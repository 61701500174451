export const NPoints      = 111
const PointArrSize = NPoints * 2

function sum(arr:number[]){
    return arr.reduce(function(a,b){
      return a + b
    }, 0);
}
function avg(arr:number[]){
    return sum(arr)/arr.length
}
function minArr(arr:number[]){
  return arr.reduce(function(a,b){
    return Math.min(a,b)
  }, 99999999);
}

function maxArr(arr:number[]){
  return arr.reduce(function(a,b){
    return Math.max(a,b)
  }, -99999999);
}


export class Point {
    x : number = -100;
    y : number = -100;

    constructor(x:number,y:number){
      this.x = x
      this.y = y
    }

    clone() : Point {
        return new Point(this.x,this.y)
    }

    get ix() : number {
      return Math.round(this.x)
    }

    get iy() : number {
      return Math.round(this.y)
    }
    
    scale(dx=0,dy=0,f=1){
        return new Point((this.x+dx)*f, (this.y+dy)*f )
    }
  
    toString() : string {
      return `[${this.ix},${this.iy}]`
    }

    public toJSON() {
      return [this.ix,this.iy]
    }

    absent(): boolean{
        return this.x === -100 || this.y === -100
    }

    flip(width:number) : Point {
        if (this.absent()) {
            return new Point(-100,-100)
        } else {
            return new Point(width-this.x,this.y)
        }
    }

    dist(p:Point) : number {
        const dx = this.x - p.x
        const dy = this.y - p.y
        return Math.sqrt(dx*dx+dy*dy)
    }

    translate(dx:number,dy:number) {
      if (!this.absent()) {
         this.x += dx
          this.y += dy
      }
      return this
    }
}

class _Keypoints {
  nose? : Point;
  top_of_the_head? : Point;
  chin? : Point;
  neck? : Point;
  spinal_cord_1? : Point;
  spinal_cord_2? : Point;
  spinal_cord_3? : Point;
  spinal_cord_4? : Point;
  spinal_cord_5? : Point;
  left_eye? : Point;
  right_eye? : Point;
  left_ear? : Point;
  right_ear? : Point;
  left_shoulder? : Point;
  right_shoulder? : Point;
  left_elbow? : Point;
  right_elbow? : Point;
  left_wrist? : Point;
  right_wrist? : Point;
  left_hip? : Point;
  right_hip? : Point;
  left_knee? : Point;
  right_knee? : Point;
  left_ankle? : Point;
  right_ankle? : Point;
  left_bigtoe? : Point;
  right_bigtoe? : Point;
  left_heel? : Point;
  right_heel? : Point;
  left_finger? : Point;
  right_finger? : Point;
  left_thumb? : Point;
  right_thumb? : Point;
  neck_back? : Point;
  front_1? : Point;
  front_2? : Point;
  front_3? : Point;
  front_4? : Point;
  front_5? : Point;
  back_1? : Point;
  back_2? : Point;
  back_3? : Point;
  back_4? : Point;
  back_5? : Point;

  left_shoulder_hand? : Point;
  right_shoulder_hand? : Point;
  left_shoulder_neck? : Point;
  right_shoulder_neck? : Point;

  left_under_ear? : Point;
  right_under_ear? : Point;
  neck_top? : Point;
  back_neck_top? : Point;

RWT? : Point;
LWT? : Point;
RWP? : Point;
LWP? : Point;
RWI? : Point;
LWI? : Point;
RWE? : Point;
LWE? : Point;
RKI? : Point;
LKI? : Point;
RKF? : Point;
LKF? : Point;
RKE? : Point;
LKE? : Point;
RKB? : Point;
LKB? : Point;
REI? : Point;
LEI? : Point;
REE? : Point;
LEE? : Point;
RAI? : Point;
LAI? : Point;
RAF? : Point;
LAF? : Point;
RAE? : Point;
LAE? : Point;
RAB? : Point;
LAB? : Point;

 left_thumb1? : Point;
 left_thumb2? : Point;
 left_thumb3? : Point;
 left_thumb4? : Point;

 left_forefinger1? : Point;
 left_forefinger2? : Point;
 left_forefinger3? : Point;
 left_forefinger4? : Point;

 left_middle_finger1? : Point;
 left_middle_finger2? : Point;
 left_middle_finger3? : Point;
 left_middle_finger4? : Point;

 left_ring_finger1? : Point;
 left_ring_finger2? : Point;
 left_ring_finger3? : Point;
 left_ring_finger4? : Point;

 left_pinky_finger1? : Point;
 left_pinky_finger2? : Point;
 left_pinky_finger3? : Point;
 left_pinky_finger4? : Point;


 right_thumb1? : Point;
 right_thumb2? : Point;
 right_thumb3? : Point;
 right_thumb4? : Point;
 right_forefinger1? : Point;

 right_forefinger2? : Point;
 right_forefinger3? : Point;
 right_forefinger4? : Point;
 right_middle_finger1? : Point;

 right_middle_finger2? : Point;
 right_middle_finger3? : Point;
 right_middle_finger4? : Point;

 right_ring_finger1? : Point;
 right_ring_finger2? : Point;
 right_ring_finger3? : Point;
 right_ring_finger4? : Point;

 right_pinky_finger1? : Point;
 right_pinky_finger2? : Point;
 right_pinky_finger3? : Point;
 right_pinky_finger4? : Point;

};

export type PointName = keyof _Keypoints;


export const AllModelPoints:PointName[] = ['nose', "top_of_the_head", "chin","neck",
'left_eye', 'right_eye',  "left_ear", "right_ear",
'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow',
'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
"left_bigtoe", "right_bigtoe", "left_heel","right_heel",
"neck_back",
"front_1","front_2",  "front_3","front_4", "front_5",
"back_1", "back_2","back_3",  "back_4", "back_5",
"left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
"left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
"RWT", 
"LWT",
"RWP",
"LWP",
"RWI",
"LWI",
"RWE",
"LWE",
"RKI",
"LKI",
"RKF",
"LKF",
"RKE",
"LKE",
"RKB",
"LKB",
"REI",
"LEI",
"REE",
"LEE",
"RAI",
"LAI",
"RAF",
"LAF",
"RAE",
"LAE",
"RAB",
"LAB",
'left_wrist',
'left_thumb1', 'left_thumb2', 'left_thumb3', 'left_thumb4',
'left_forefinger1', 'left_forefinger2', 'left_forefinger3', 'left_forefinger4',
'left_middle_finger1', 'left_middle_finger2', 'left_middle_finger3', 'left_middle_finger4',
'left_ring_finger1', 'left_ring_finger2', 'left_ring_finger3', 'left_ring_finger4',
'left_pinky_finger1', 'left_pinky_finger2', 'left_pinky_finger3', 'left_pinky_finger4',
'right_wrist',
'right_thumb1', 'right_thumb2', 'right_thumb3', 'right_thumb4', 'right_forefinger1',
'right_forefinger2', 'right_forefinger3', 'right_forefinger4', 'right_middle_finger1',
'right_middle_finger2', 'right_middle_finger3', 'right_middle_finger4',
'right_ring_finger1', 'right_ring_finger2', 'right_ring_finger3', 'right_ring_finger4',
'right_pinky_finger1', 'right_pinky_finger2', 'right_pinky_finger3', 'right_pinky_finger4'

]
const oldSpinalCordPoints : PointName[] =  ["spinal_cord_1", "spinal_cord_2", "spinal_cord_3", "spinal_cord_4", "spinal_cord_5"]
const oldFingers : PointName[] =  ["left_finger",     "right_finger",      "left_thumb",  "right_thumb"]
const newFingers : PointName[] =  ["left_forefinger4","right_forefinger4", "left_thumb4", "right_thumb4"]

const front : PointName[] =  ["front_1","front_2",  "front_3","front_4", "front_5"]
const back  : PointName[] =  ["back_1", "back_2","back_3",  "back_4", "back_5"]


export const XModelPoints:PointName[] = [...AllModelPoints, ...oldSpinalCordPoints,...oldFingers]

export const belowKnees:string[] = [
  'left_ankle', 'right_ankle', 'ankles',
  "left_bigtoe", "right_bigtoe", 'bigtoes',
  "left_heel","right_heel", 'heels'
]

export const bothLimbs:string[] = [
  'hips',
  'knees',
  'ankles',
  'shoulders',
  'elbows',
  'wrists',
]

export const hands:string[] = [
              'left_shoulder', 'right_shoulder',  /* 'shoulders', --- this is used also for lower body features*/
              'left_elbow', 'right_elbow', 'elbows',
              'left_wrist', 'right_wrist', 'wrists',
              "left_finger","right_finger", 'fingers',
              "left_thumb", "right_thumb", 'thumbs'
]

export const leftHand:string[] = [
  'left_shoulder', 'shoulders',
  'left_elbow', 'elbows',
  'left_wrist', 'wrists',
  "left_finger",
  "left_thumb"
]

export const rightHand:string[] = [
  'right_shoulder', 'shoulders',
  'right_elbow', 'elbows',
  'right_wrist', 'wrists',
  "right_finger",
  "right_thumb"
]


export const legs:string[] = [
              'left_hip', 'right_hip' , /* 'hips', ---- this is used also for upper body*/
              'left_knee', 'right_knee', 'knees',
              'left_ankle', 'right_ankle', 'ankles',
              "left_bigtoe", "right_bigtoe", 'bigtoes',
              "left_heel","right_heel", 'heels'
]

export const leftLeg:string[] = [
  'left_hip' , 'hips',
  'left_knee', 'knees',
  'left_ankle', 'ankles',
  "left_bigtoe",
  "left_heel"
]

export const rightLeg:string[] = [
  'right_hip' , 'hips',
  'right_knee',  'knees',
  'right_ankle', 'ankles',
  "right_bigtoe",
  "right_heel"
]

export const spine:string[] = [
              "spinal_cord_1", "spinal_cord_2", "spinal_cord_3", "spinal_cord_4", "spinal_cord_5",
              "front_1","front_2",  "front_3","front_4", "front_5",
              "back_1", "back_2","back_3",  "back_4", "back_5",
              "neck_back", "neck", "top_of_the_head",
]

export const head:string[] = ['nose', "top_of_the_head", "chin",
              'left_eye', 'right_eye',  "left_ear", "right_ear",
]

export const PeripheralPoints:string[] = [
              'nose', "top_of_the_head", "chin",
              'left_eye', 'right_eye',  "left_ear", "right_ear",
              "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
              "left_finger","right_finger", "left_thumb", "right_thumb",
]

// Use the following 2 dictionaries and 3 lists for positioning states.
export const pluralJointsConvertSingle: { [key: string]: string[] } = {ankles: ["left_ankle", "right_ankle"], hips: ["left_hip", "right_hip"],
                                                          knees: ["left_knee", "right_knee"], shoulders: ["left_shoulder", "right_shoulder"],
                                                          elbows: ["left_elbow", "right_elbow"], wrists: ["left_wrist", "right_wrist"] };


export class BBox {
  constructor (public x1:number,public y1:number,public x2:number,public y2:number) {
  }
  toString(){
    return `(${Math.round(this.x1)},${Math.round(this.y1)})-(${Math.round(this.x2)},${Math.round(this.y2)})`
  }

  get height(){
    return this.y2 - this.y1
  }
  get width(){
    return this.x2 - this.x1
  }

  get iHeight(){
    return Math.floor(this.y2 - this.y1)
  }
  get iWidth(){
    return Math.floor(this.x2 - this.x1)
  }
  get ix1(){
    return Math.floor(this.x1)
  }
  get ix2(){
    return Math.floor(this.x2)
  }
  get iy1(){
    return Math.floor(this.y1)
  }
  get iy2(){
    return Math.floor(this.y2)
  }



  center():[number,number]{
    const cx = (this.x2 + this.x1)/2
    const cy = (this.y2 + this.y1)/2
    return [cx,cy]
  }

  square():BBox {
    const [cx,cy] = this.center()
    const sx = this.x2 - cx
    const sy = this.y2 - cy
    const s = Math.max(sx,sy)!
    return new BBox(cx-s,cy-s,cx+s,cy+s)
  }

  enlarge(factor:number){
    const [cx,cy] = this.center()
    const sx = this.x2 - cx
    const sy = this.y2 - cy
    return new BBox(cx-sx*factor,cy-sy*factor,cx+sx*factor,cy+sy*factor)
  }

  overlap(x1:number, y1:number, x2:number, y2:number){
      return new BBox(
        Math.max(this.x1,x1)!,
        Math.max(this.y1,y1)!,
        Math.min(this.x2,x2)!,
        Math.min(this.y2,y2)!
      )
  }

}

const absent : number = NaN
function isAbsent(n:number) : boolean{
    return isNaN(n)
}

export class KeypointsArray {
    data : Float32Array


    constructor(data? : Float32Array) {
        if (data) {
            this.data = data
        } else {
            this.data = new Float32Array(PointArrSize)
            this.reset()
        }
    }

    reset(){
        for(let i in this.data) {
            this.data[i] = absent
        }
    }

    set(i:number,x:number,y:number){
        this.data[2*i]   = x
        this.data[2*i+1] = y
    }

    bbox(minPoints=6):BBox|null {

        let minX = 0,minY= 0,maxX= 0,maxY= 0,nPoints=0;
        for(let i=0; i<NPoints; i++) {
            const x = this.data[2*i]
            if (!isAbsent(x)) {
                const y = this.data[2*i+1]
                if (!nPoints) {
                    minX = x
                    minY = y
                    maxX = x
                    maxY = y
                }
                nPoints++
                if (x<minX){
                    minX = x
                }
                if (y<minY){
                    minY = y
                }
                if (x>maxX){
                    maxX = x
                }
                if (y>maxY){
                    maxY = y
                }

            }
        }
        if (nPoints<minPoints) {
            return null;
        }
        return new BBox(minX,minY,maxX,maxY);

    }


    get keypoints() : Keypoints {
        const result = new Keypoints()
        for(let i=0; i<NPoints; i++) {
            const x = this.data[2*i]
            if (!isAbsent(x)) {
                const y = this.data[2*i+1]
                const res_point = new Point(x,y);
                result.set(AllModelPoints[i], res_point);
            }
        }
        result.fillOldPoints()
        return result
    }

    static fromKeypoints(kp:Keypoints) : KeypointsArray {
        const res = new KeypointsArray()
        for(let i=0; i<NPoints; i++) {
            const p = AllModelPoints[i]
            const point = kp.get(p)
            if (point) {
                res.set(i,point.x,point.y)
            }
        }
        return res   
    }


    static fromJSON(data:any) : KeypointsArray {

      const res = new KeypointsArray()
  
      if (!data) {
        return res
      }
  
      for(let i=0; i<NPoints; i++) {
        const point = AllModelPoints[i]
        let val =  data[point]
        if (val) {
          if (val[0]==-100){
                continue
          }
          res.set(i,val[0],val[1])
        }
      }  
      return res
    }
  

}


export class Keypoints extends _Keypoints {

  set = (k:PointName, v:Point) => {
      this[k] = v;
  }

  get = (k:PointName) : Point|undefined => {
      return this[k]
  }

  delete = (k:PointName) => {
     this[k] = undefined
  }


  hasPoint = (point : PointName, inFrame: boolean = false) : boolean => {
    //if (inFrame) {
      return this[point] !== undefined && this[point]!.y > -10 && this[point]!.x > -10;
    //}
    //return this[point] !== undefined
  }

  hasPoints = (points:PointName[], inFrame: boolean = false) : boolean => {
    return points.every(k=>this.hasPoint(k, inFrame))
  }

  countPresentPoints = (points:PointName[], inFrame: boolean = false) : number => {
    let count = 0
    for (let p of points) {
      if (this.hasPoint(p, inFrame)) {
        count ++
      }
    }
    return count;
  }

  hasSomePoints = (points: PointName[], inFrame: boolean = false): boolean => {
    return points.some(p => this.hasPoint(p, inFrame));
  }

  points = ():PointName[] => {
    return XModelPoints.filter(p=>this.hasPoint(p))
  }


  pointsBBox(points: PointName[], inFrame: boolean = false):BBox|null {
    const pp = points.filter(p=>this.hasPoint(p,inFrame))
    if (!pp.length) {
      return null;
    }

    const xs = pp.map(p=>this._getX(p));
    const x2 = Math.max(...xs);
    const x1 = Math.min(...xs);

    const ys = pp.map(p=>this._getY(p));
    const y2 = Math.max(...ys);
    const y1 = Math.min(...ys);
    return new BBox(x1!,y1!,x2!,y2!);
  }


  _getX(p:PointName) : number{
    return this[p]!.x
  }
  _getY(p:PointName) : number{
    return this[p]!.y
  }

  _distX(p1:PointName,p2:PointName) : number{
    return this[p1]!.x - this[p2]!.x
  }
  _distY(p1:PointName,p2:PointName) : number{
    return this[p1]!.y - this[p2]!.y
  }

  distX(p1:PointName,p2:PointName) : number | null{
    if (this.hasPoints([p1,p2])) {
      return this[p1]!.x - this[p2]!.x
    } else {
      return null
    }
  }

  dist(p1:PointName,p2:PointName) : number | null{
    if (this.hasPoints([p1,p2])) {
      const dx = this._distX(p1,p2)
      const dy = this._distY(p1,p2)
      return Math.sqrt(dx*dx+dy*dy)
    } else {
      return null
    }
  }

  calcAngle(joints: [PointName,PointName], flipX:boolean=false): number|null {
    if (!this.hasPoints(joints)){
      return null;
    }
    if (flipX) {
        return Math.atan2(this._distY(...joints), this._distX(...joints)) * 180 / Math.PI;
    } else {
        return Math.atan2(this._distY(...joints), - this._distX(...joints)) * 180 / Math.PI;
    }
  }

 // calculate angle between two lines
 calcAngle4(joints: [PointName,PointName,PointName,PointName]): number|null {
  if (!this.hasPoints(joints)){
    return null;
  }
  let a1 = this.calcAngle([joints[0],joints[1]]);
  let a2 = this.calcAngle([joints[3],joints[2]]); // note : reverse order

  if (a1 == null || a2 == null) return null;
  let val = a1-a2;
  if(val > 180) val -= 360;
  if(val <= -180) val += 360;
  //console.log("a1=",a1,"a2=",a2,"val=",val);
  return val;
}


  // calculate angle between two lines
  calcAngle3(joints: [PointName,PointName,PointName]): number|null {
    if (!this.hasPoints(joints)){
      return null;
    }
    let a1 = this.calcAngle([joints[1],joints[0]]);
    let a2 = this.calcAngle([joints[1],joints[2]]);

    if (a1 == null || a2 == null) return null;
    let val = a1-a2;
    if(val > 180) val -= 360;
    if(val <= -180) val += 360;
    //console.log("a1=",a1,"a2=",a2,"val=",val);
    return val;
  }
  // calculate angle between line and yAxis
  calcAngleYaxis(joints: [PointName,PointName]): number|null {
    if (!this.hasPoints(joints)){
      return null;
    }
    let a1 = this.calcAngle([joints[1],joints[0]]);
    let a2 = 270;

    if (a1 == null || a2 == null) return null;
    let val = a1-a2;
    if(val > 180) val -= 360;
    if(val <= -180) val += 360;
    //console.log("a1=",a1,"a2=",a2,"val=",val);
    return val;
  }
  calcAngleYaxisC(joints: [PointName,PointName]): number|null {
    if (!this.hasPoints(joints)){
      return null;
    }
    let a2 = this.calcAngle([joints[1],joints[0]]);
    let a1 = 90;

    if (a1 == null || a2 == null) return null;
    let val = a1-a2;
    if(val > 180) val -= 360;
    if(val <= -180) val += 360;
    //console.log("a1=",a1,"a2=",a2,"val=",val);
    return val;
  }
  

  averageHeight( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p))
    if (presentPoints.length === 0){
      return null
    }
    const ys = presentPoints.map(p=>this.get(p)!.y)
    return avg(ys)
  }

  averageX( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p))
    if (presentPoints.length === 0){
      return null
    }
    const xs = presentPoints.map(p=>this.get(p)!.x)
    return avg(xs)
  }

  getMinY(points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p))
    if (presentPoints.length === 0){
      return null
    }
    const xs = presentPoints.map(p=>this.get(p)!.y)
    return minArr(xs)
  }

  getMaxY(points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p))
    if (presentPoints.length === 0){
      return null
    }
    const xs = presentPoints.map(p=>this.get(p)!.y)
    return maxArr(xs)
  }

  angle(joints: [PointName,PointName]): number {
    if (!this.hasPoints(joints)){
      return NaN
    }
    return Math.atan2(this._distY(...joints), - this._distX(...joints)) * 180 / Math.PI;
  }

  angle3(joints: [PointName,PointName,PointName]): number {
    if (!this.hasPoints(joints)){
      return NaN
    }
    const [A,B,C] = joints
    return 180 - this.angle([A,B]) + this.angle([B,C])
  }


  isProfile = () => {
    const spineLength = this.dist('spinal_cord_5', 'spinal_cord_1');
    if(!spineLength){
      //Can't calculate..-> error?
      return false;
    }
    const spineDist4 = this.dist('front_4', 'back_4');
    const spineDist3 = this.dist('front_3', 'back_3');
    if(spineDist4 == null && spineDist3 == null) {
      //Can't calculate..-> error?
      return false;
    }

    const spineDist = (!spineDist4 || !spineDist3) ? (spineDist3 ?? spineDist4) : (spineDist3+spineDist4) / 2;

    //const hipsDist = this.dist("right_hip", "left_hip");
    //const shouldersDist = this.dist("right_shoulder", "left_shoulder");

    //console.log(`isProfile spineLength = ${spineLength}, spineDist = ${spineDist}}`)

    if(!spineDist || spineDist < 0.05*spineLength){
      //console.log(`isProfile user is frontal spineDist5 = ${spineDist5}`)
      return false;
    }
/*

    if((hipsDist && shouldersDist) && hipsDist > 5 && shouldersDist > 5){
      console.log(`isProfile hipsDist & shouldersDist = ${hipsDist}, ${shouldersDist}`)
      return false;
    }
*/
    //console.log(`isProfile user is profile`)
    return true;
  }

  isFloor = (/*bodyLen: number*/) : boolean => {
    // if ankles are not the lowest place in body -> user is on mat
    let minThresh = this.dist('spinal_cord_5', 'spinal_cord_1'); 
    if(!minThresh) return true; //I don't knoe and I don't want to kill the exercise
    minThresh = minThresh/4;

    const leftAnkleY = this.get('left_ankle')?.y;
    const rightAnkleY = this.get(`right_ankle`)?.y;

    let minAnkleY = (!leftAnkleY) ? rightAnkleY : (!rightAnkleY? leftAnkleY : Math.min(leftAnkleY,rightAnkleY));
    if(!minAnkleY) return true; //I don't knoe and I don't want to kill the exercise

    let points: PointName[]  = ['left_shoulder','right_shoulder',"left_hip", "right_hip","left_knee", "right_knee", "left_wrist", "right_wrist", "left_elbow", "right_elbow"];
    for (let i of points){
      let py = this.get(i)?.y;
      //console.log(`keypoints:checking isFloor MaxY= ${py},  leftAnkleY = ${leftAnkleY}, rightAnkleY= ${rightAnkleY}, minThresh = ${minThresh}`);
      if(py && ((minAnkleY-py) < minThresh)) return true;
    }
    
    //console.log(`keypoints:isFloor == false`);
    return false;
  }

  isSeated = () => {
    const maxKneesY = this.getMaxY(['left_knee','right_knee']);
    const maxHipsY = this.getMaxY(['left_hip','right_hip']);
    const spineLength = this.dist('spinal_cord_5', 'spinal_cord_1');
    const spineY = this._distY('spinal_cord_5', 'spinal_cord_1');

    //console.log(`isSeated: maxKneesY = ${maxKneesY}, maxHipsY = ${maxHipsY}, spineLength= ${spineLength}, spineY = ${spineY}`)

    if(!maxKneesY || !maxHipsY || !spineLength || !spineY){
      return false;
    }

    if(maxKneesY-maxHipsY >  spineLength*0.35){ //thigh Y
      return false;
    }

    if(spineY < spineLength*0.5){ //not lying
      return false;
    }

    return true;
  }

  proneOrSupine= () : number => {
    if(!this.isProfile() || !this.isFloor()){
      return 0;
    }
    const back = this.averageHeight(['back_3', 'back_4', 'back_5']); //this.get('back_5')?.y;
    const front = this.averageHeight(['front_3', 'front_4', 'front_5']); //this.get(`front_5`)?.y;
    //console.log(`proneOrSupine: back = ${back}, front = ${front}`)
    if(!back || !front){
      return 0;
    }
    if(back > front){ //supine
      return 1;
    }
    //prone
    return 2;
  }

  isProne = () : boolean => {
    if(this.proneOrSupine() == 2){
      return true;
    }
    //console.log(`isProne ==false ${this.proneOrSupine()}`);
    return false;
  }

  isSupine = () : boolean => {
    if(this.proneOrSupine() == 1){
      return true;
    }
    return false;
  }

  isQuadruped = () : boolean => {
    if(!this.isProne()){
      //console.log(`isQuadruped == false because notprone`);
      return false;
    }
    const back3 = this.get('spinal_cord_3')?.y;
    const maxY_knee = this.getMaxY([`left_knee`, `right_knee`]);
    const maxY_wrist = this.getMaxY([`left_wrist`, `right_wrist`]);

    //console.log(`back3 = ${back3}, minKnee = ${maxY_knee}, minWrist = ${maxY_wrist}`);
    if(!back3 || !maxY_knee || !maxY_wrist){
      //console.log(`isQuadruped == false, back3 = ${back3}, minKnee = ${maxY_knee}, minWrist = ${maxY_wrist}`);
      return false;
    }

    if(back3 < maxY_knee && back3 <maxY_wrist){
      return true;
    }
    //console.log(`isQuadruped == false`);
    return false;
  }

  bbox(minPoints=6):BBox|null{
    const pp = this.points();
    if (pp.length<minPoints) {
      return null;
    }

    const xs = pp.map(p=>this._getX(p));
    const x2 = Math.max(...xs);
    const x1 = Math.min(...xs);

    const ys = pp.map(p=>this._getY(p));
    const y2 = Math.max(...ys);
    const y1 = Math.min(...ys);
    return new BBox(x1!,y1!,x2!,y2!);
  }

  asMap(){
    let m = new Map<string, Point>();
    let p:PointName;
    for (p of this.points()) {
      m.set(p,this[p]!)
    }
    return m
  }

  static fromMap(m : Map<string, Point>|null):Keypoints {
    let k = new Keypoints();
    if (!m) {
      return k
    }
    for(let p of XModelPoints) {
      let v =  m.get(p)
      if (v) {
        k[p] = v
      }
    }
    return k
  }

  toString() : string{
    let res = ""
    for (let p of this.points()) {
      res += `${p}:${this[p]} `
    }
    return res;
  }

  public toJSON(){
    const res : any = {}
    for (let p of this.points()) {
      const pp  = this[p]
      if (pp) {
        res[p] = [ Math.round(pp.x), Math.round(pp.y)]
      }
    }
    return res
  }

  static fromJSON(data:any) : Keypoints {

    const kp = new Keypoints();

    if (!data) {
      return kp
    }

    for(let point of XModelPoints) {
      let val =  data[point]
      if (val) {
        if (val[0]==-100){
              continue
        }
        kp[point] = new Point(val[0],val[1])
      }
    }
    // if (kp.countPresentPoints(oldPoints,true) === 0) {
    //     kp.fillSpinalCord()
    // }
    kp.fillOldPoints()


    return kp
  }


  static flipPointName(p:PointName): PointName {
    if (p.startsWith("left_")) {
        return p.replace(/^left_/,"right_") as PointName
    }
    if (p.startsWith("right_")) {
        return p.replace(/^right_/,"left_") as PointName
    }
    if (p.startsWith("L")) {
      return p.replace(/^L/,"R") as PointName
    }
    if (p.startsWith("R")) {
        return p.replace(/^R/,"L") as PointName
    }
    return p
  }

  flip(width:number) {
      const res = new Keypoints()
      for(let p of this.points()) {
        res.set(Keypoints.flipPointName(p), this.get(p)!.flip(width))
        //console.log(` ========> ${width} ${p} ${Keypoints.flipPointName(p)} ${this.get(p)} ${res.get(Keypoints.flipPointName(p))}`)
      }
      return res
  }

  resize(factor: number) {
    const res = new Keypoints();

    for (let p of this.points()) {
      res.set(p, new Point(this._getX(p) * factor, this._getY(p) * factor));
    }

    return res
  }

  translate(dx:number,dy:number) {
    for (let p of this.points()) {
      this.get(p)?.translate(dx,dy)
    }
    return this
  }


  maxX( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p) && this.get(p)!.x >= 0)
    if (presentPoints.length === 0){
      return null
    }
    const xs = presentPoints.map(p=>this.get(p)!.x)
    return Math.max(...xs)
  }

  minX( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p) && this.get(p)!.x >= 0)
    if (presentPoints.length === 0){
      return null
    }
    const xs = presentPoints.map(p=>this.get(p)!.x)
    return Math.min(...xs)
  }

  maxY( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p) && this.get(p)!.y >= 0)
    if (presentPoints.length === 0){
      return null
    }
    const ys = presentPoints.map(p=>this.get(p)!.y)
    return Math.max(...ys)
  }

  minY( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p) && this.get(p)!.y >= 0)
    if (presentPoints.length === 0){
      return null
    }
    const ys = presentPoints.map(p=>this.get(p)!.y)
    return Math.min(...ys)
  }

  copyPoints(from:PointName[],to:PointName[]) {
      for(let i=0; i<from.length; i++) {
        this[to[i]] = this[from[i]]?.clone()
      }
  }

  fillOldPoints(){
       if (this.countPresentPoints(front) > this.countPresentPoints(back)){
            this.copyPoints(front,oldSpinalCordPoints)
       } else {
            this.copyPoints(back,oldSpinalCordPoints)
       }
       this.copyPoints(newFingers,oldFingers)
  }
}


export function fromMap(m : Map<string, Point>):Keypoints {
  let k = new Keypoints();
  if (!m) {
    return k
  }
  for(let p of XModelPoints) {
    let v =  m.get(p)
    if (v) {
      k[p] = v
    }
  }
  return k
}


export function fromArray(keypointsArray: Array<any>, desiredHeight: number = 1, sourceHeight: number = 1) {
  const resizeFactor = desiredHeight / sourceHeight;
  return keypointsArray.map(kpData => {
        let kp = new Keypoints();
        for(let point of XModelPoints) {
            if (kpData[point] && kpData[point][0] > -50 && kpData[point][1] > -50 ) {
                kp.set(point,new Point(kpData[point][0] * resizeFactor, kpData[point][1]* resizeFactor))
            }
        }
        kp.fillOldPoints()
        return kp
  });
}
