import { Keypoints, PointName , drawSkeletonAnnotator} from "@kemtai/keypoints";


/*
    A callback function called after a keypoints computed for 
    the current frame.
    
    keypoints : Keypoints - keypoints object like {nose:{x:19,y:21},...}
    img       : ImageData or HTMLVideoElement - the current frame
*/
export function onFrameSkeleton(keypoints : Keypoints, img : CanvasImageSource | ImageData) {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement   
    if (!canvas) {
        return
    }
    const ctx  = canvas.getContext('2d')
    if (!ctx) {
        return
    }

    if (img instanceof ImageData) {
        ctx.putImageData(img, 0, 0);
    } else {
        ctx.drawImage(img, 0, 0);
    }
    

    if (keypoints) {
        drawSkeletonAnnotator(ctx, keypoints)
    }
}
/*
export function drawSkeleton(ctx:CanvasRenderingContext2D, keypoints:Keypoints) {
    //skeleton lines to draw
    const skeletonLines: [PointName,PointName][] = [
        ['neck', 'right_shoulder'],
        ['neck', 'left_shoulder'],
        ['right_shoulder', 'right_elbow'],
        ['right_elbow', 'right_wrist'],
        ['right_wrist', 'right_finger'],
        ['right_wrist', 'right_thumb'],
        ['left_shoulder', 'left_elbow'],
        ['left_elbow', 'left_wrist'],
        ['left_wrist', 'left_finger'],
        ['left_wrist', 'left_thumb'],
        ['right_hip', 'right_knee'],
        ['right_knee', 'right_ankle'],
        ['left_hip', 'left_knee'],
        ['left_knee', 'left_ankle'],
        ['right_ankle', "right_bigtoe"],
        ['left_ankle', "left_bigtoe"],
        ["back_1","neck_back"],
        ["back_2","back_1"],
        ["back_3","back_2"],
        ["back_4","back_3"],
        ["back_5","back_4"],
        ["left_hip","back_5"],
        ["right_hip","back_5"],
        ["front_1","neck"],
        ["front_2","front_1"],
        ["front_3","front_2"],
        ["front_4","front_3"],
        ["front_5","front_4"],
        ["left_hip","front_5"],
        ["right_hip","front_5"],
        ['chin', 'nose'],
        ['left_eye', 'nose'],
        ['right_eye', 'nose'],
        ['left_eye', 'left_ear'],
        ['right_eye', 'right_ear'],    
    ];
    ctx.lineWidth = 3;
    ctx.strokeStyle = "cyan"; // line color

    for (let line of skeletonLines) {
        const point1 = keypoints[line[0]]
        const point2 = keypoints[line[1]]
        if (point1 && point2) {
            ctx.beginPath();
            ctx.moveTo(point1.x, point1.y);
            ctx.lineTo(point2.x, point2.y);
            ctx.stroke();
        }
    }
}

*/