

const serverUrl  = 'https://api.kemtai.com'
const version = "skelapi-v-1.0.0";

class Config  {
    app             = "skelapi-app"
    logServerUrl    = serverUrl


    version         = version
    isProduction       = false

    _model  : string|undefined = undefined
    _maxFPS : number|undefined = undefined
    modelSelectionPolicy : string = "automatic"
    fakeSkeleton = false

    init(params : any) {
        this.setParams(params)
    }

    setParams(params : {[key:string]:any}) {
        for(let key in params) {
            (this as any)[key] = params[key] as any
        }
    }


}
const config = new Config()
export default config
