
import logger from '@kemtai/logger'

declare global {
    interface BatteryManager {
        charging: boolean
        chargingTime: number
        dischargingTime: number,
        level : number
    }
    interface Navigator {
        getBattery : undefined | (() => Promise<BatteryManager>);
    }
}


export async function batteryStatus() {
    if (!navigator.getBattery) {
        return null
    }

    const battery = await navigator.getBattery()
    //console.log("batteryStatus:",battery)
    return battery
}


export async function logBattery() {   
    const battery = await batteryStatus()
    if (battery) {
        logger.event("batteryStatus", {
                            charging:        battery.charging, 
                            chargingTime:    battery.chargingTime, 
                            dischargingTime: battery.dischargingTime,
                            batteryLevel:    battery.level * 100,
                            level: "log"
                    })
    }
}
