
import {Keypoints, PointName} from "./keypoints";

const limb_ratios : [PointName,PointName,number][] = [
    ["left_hip",      "right_hip", 14.684130099661633],
    ['left_shoulder', 'right_shoulder', 23.714863868900174],
    ['right_shoulder', 'right_hip', 35.45872558159769],
    ['left_shoulder', 'left_hip', 35.14085550072475],
    ['neck', 'top_of_the_head', 22.25348654019734],
    ['chin', 'top_of_the_head', 16.396504942864063],
    ['left_ear', 'right_ear', 11.485706218305486],
    ['right_shoulder', 'right_elbow', 18.50796745625071],
    ['right_elbow', 'right_wrist', 16.451164858139975],
    ['left_shoulder', 'left_elbow', 18.54462757891701],
    ['left_elbow', 'left_wrist', 16.35297530312522],
    ['right_hip', 'right_knee', 27.161477611220555],
    ['right_knee', 'right_ankle', 22.02438122631459],
    ['left_hip', 'left_knee', 27.231333908623917],
    ['left_knee', 'left_ankle', 21.949303234502054],
    ['neck', 'spinal_cord_5', 40.28947204225279],
    ['neck_back', 'spinal_cord_5', 44.442299837339554]
];


// sort array ascending
function _sort(arr : number[]) {
    arr.sort((a, b) => a - b);
    return arr
}
function percentile(arr: number[], q:number){
    const sorted = _sort(arr);
    const pos = ((sorted.length) - 1) * q / 100;
    const base = Math.floor(pos);
    const rest = pos - base;
    if ((sorted[base + 1] !== undefined)) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};


export default function predictedHeight(kp: Keypoints) {
    let predictors: number[] = [];
    for(let pd of limb_ratios) {
      let [p1, p2, l] = pd;
      let ll = kp.dist(p1, p2);

      if (ll) {
        const hh = ll / l * 100;
        predictors.push(hh);
      }
    }

    if (predictors.length === 0) {
     return null;
    }

    return percentile(predictors, 85) * 1.08;
}

export function heightNorm(v:number,h:number) {
    return v / h * 175
}


export function getBodyLen(kp:Keypoints){
    //let len=0;
    //shin + thigh + back
    //shin
    let len1 : any = kp.dist("right_ankle", "right_knee");
    let len2 : any = kp.dist("left_ankle", "left_knee");
    let lenShin = Math.max(len1,len2);
    let noShin = (!lenShin || lenShin==null || lenShin == 0);
    //thigh
    len1 = kp.dist("right_knee", "right_hip");
    len2 = kp.dist("left_knee", "left_hip");
    let lenThigh = Math.max(len1,len2);
    //console.log("lenThisgh = ", lenThigh, len1, len2)
    let noThigh = (!lenThigh || lenThigh==null || lenThigh == 0);
    //back
    len1 = kp.dist("spinal_cord_1", "spinal_cord_2");
    len2 = kp.dist("spinal_cord_2", "spinal_cord_3");
    let len3 = kp.dist("spinal_cord_3", "spinal_cord_4");
    let len4 = kp.dist("spinal_cord_4", "spinal_cord_5");
    //console.log(`back1: ${len1}, ${len2}, ${len3}, ${len4} , ${kp.get("spinal_cord_5")}`)
    let lenBack;
    let noBack = false;
    if(len1 != null && len2 !=null && len3 !=null && len4 !=null) {
        lenBack = len1+len2+len3+len4;
    }else{
        len1 = kp.dist("right_hip", "right_shoulder");
        len2 = kp.dist("left_hip", "left_shoulder");
        lenBack = Math.max(len1,len2);
        //console.log(`back2: ${len1}, ${len2}`)
        noBack = (!lenBack || lenBack==null || lenBack == 0);
    }
    
    /*
    console.log("bodyLen0= ",lenShin.toFixed(1), lenThigh.toFixed(1), lenBack.toFixed(1));
    if(noShin || noThigh || noBack) {
        //console.log("Cannot predict body height");
        return null;
    }
    return (lenShin + lenThigh + lenBack);
    */
    if(noShin && noThigh && noBack) {
        //console.log("Cannot predict body height");
        return null;
    }
    
    
    let factor = 1;
    const shinFactor = 0.3;
    const thighFactor = 0.35;
    const backFactor = 0.35;
    if (noShin) factor -= shinFactor;
    if (noThigh) factor -= thighFactor;
    if (noBack) factor -= backFactor;
    let len = (lenShin + lenThigh + lenBack)/factor;
    //console.log(`bodyLen1=${len.toFixed(1)} shin = ${lenShin.toFixed(1)} thigh = ${lenThigh.toFixed(1)} back = ${lenBack.toFixed(1)}`);
 
    // correct possible errors
    let thres = 0.75;
    factor = 1; 
    if (lenShin < thres*shinFactor*len){  factor -= shinFactor; lenShin = 0; /*console.log(`fixing bodylen shin`);*/}
    if (lenThigh < thres*thighFactor*len) {factor -= thighFactor; lenThigh =  0; /*console.log(`fixing bodylen Thigh`);*/} 
    if (lenBack < thres*backFactor*len) {factor -= backFactor; lenBack = 0; /*console.log(`fixing bodylen back`);*/}
    len =  (lenShin + lenThigh + lenBack)/factor;
    //console.log(`bodyLen2=${len.toFixed(1)} shin = ${lenShin.toFixed(1)} thigh = ${lenThigh.toFixed(1)} back = ${lenBack.toFixed(1)}`);
    return (len);
    
    
}




  

