

import _QCStats from './qc-stats.json';
import { Keypoints,PointName } from './keypoints';
import predictedHeight from './height';

type QCStat = [PointName,PointName,number];
const QCStats : QCStat[] = _QCStats as QCStat[];


export function fixKeypoints(keypoints:Keypoints, threshold:number=1.5) {
/*

def fix(kp:keypoints, thres:float=2.0):
    print()
    it = 0
    while True:
        it += 1
        h = predict_height(kp)
        if not h:
            return kp
        
        score = defaultdict(int)
        for p1,p2,t in QC3_STATS:
            if kp.has_point(p1) and kp.has_point(p2):
                    d = kp.dist(p1,p2)
                    if not d:
                        continue
                    d = d / h * 175.
                    if d > t * thres:
                        score[p1] += d / t
                        score[p2] += d / t

        if not score:
            return kp
        
        points = sorted(score.keys(), key=lambda x:score[x], reverse=True)
        print(it, points[0], score[points[0]])
        kp.del_point(points[0])


*/
    //performance.mark("fixKeypoints-start");
    let it = 0;
    while (true) {
        it += 1;
        const h = predictedHeight(keypoints);
        if (!h) {
            break
        }

        const score : {[key:string]:number} = {}
        for (const [p1,p2,t] of QCStats) {
            if (keypoints.hasPoint(p1) && keypoints.hasPoint(p2)) {
                const d = keypoints.dist(p1,p2);
                if (!d) {
                    continue;
                }
                const d2 = d / h * 175.;
                if (d2 > t * threshold) {
                    score[p1] = (score[p1] || 0) + d2 / t;
                    score[p2] = (score[p2] || 0) + d2 / t;
                }
            }
        }
        const badPointCandidated = Object.keys(score)
        if (!badPointCandidated.length) {
            break
        }

        const badPointCandidatedSorted = badPointCandidated.sort((a,b) => score[b] - score[a]);
        const badPoint = badPointCandidatedSorted[0];
        //console.log("Delete bad point : ", it, badPoint, score[badPoint]);
        keypoints.delete(badPoint as PointName);
    } 
    //performance.mark("fixKeypoints-done");

    //console.log("fixKeypoints took",performance.measure("fixKeypoints", "fixKeypoints-start","fixKeypoints-done").duration);
    return keypoints;
}


export class Stabilazer {
    lastKeypoints : Keypoints|null = null;

    process(keypoints:Keypoints):Keypoints {
        if (!this.lastKeypoints) {
            this.lastKeypoints = keypoints;
            return keypoints;
        }    
        /*
        const h = predictedHeight(keypoints);
        if (!h) {
             this.lastKeypoints = null
             return keypoints;
        }
        console.log("h factor = ", Math.round(175/h*100)/100 , " cm/px ", Math.round(h/175*100)/100 , " px/cm");
        */
        for (const point of keypoints.points()) {
            const p1 = this.lastKeypoints[point]
            const p2 = keypoints[point]
            if (p1 && p2) {
                //const d = p1.dist(p2) / h * 175;
                const d = p1.dist(p2);
                if (d<5){
                    keypoints[point] = p1;
                    //console.log("Stabilized", point, d);
                }
            }
        }
    
        this.lastKeypoints = keypoints;
        return keypoints;
    }


    static processArray(keypoints:Keypoints[]):Keypoints[] {
        /*
        performance.mark("processArray-start");

        const stabilazer = new Stabilazer();
        const result = keypoints.map(kp => stabilazer.process(fixKeypoints(kp)));
        performance.mark("processArray-done");
        console.log(`processArray (${keypoints.length}) took`,performance.measure("processArray", "processArray-start","processArray-done").duration);

        return result;
        */

        const result = keypoints.map(kp => fixKeypoints(kp));
        return result;
    }
}