import logger from '@kemtai/logger'

declare global {
    interface HTMLVideoElement { 
        playsInline: boolean; 
        captureStream(frameRate?: number): MediaStream;
    }
    interface HTMLCanvasElement { 
        captureStream(frameRate?: number): MediaStream;
    }

}

const videoWidth = 352;
const videoHeight = 256;


function controlSum(res:ImageData) : number {
    let s = 0
    for(let i = 0; i< Math.min(100,res.data.length); i++) {
        s += res.data[i]
    }
    return s
}



class CameraController {
    private stream: MediaStream|null = null;
    private reconnect : (()=>Promise<MediaStream|null>)|null = null
    private videoRef: HTMLVideoElement|null = null;
    private canvasRef: HTMLCanvasElement|null = null;
    //active: boolean  = false;
    frameNum = 0
    isTesting = false;
    stopped : boolean = false;
    reconnecting: boolean = false;
    
    setCamera(stream:MediaStream, reconnect : ()=>Promise<MediaStream|null> ) {
        //console.log("**********", stream.getVideoTracks())
        this.reconnect = reconnect
        const CameraSettings = stream.getVideoTracks()[0].getSettings()
        //console.log("**********", CameraSettings)
        logger.event("CameraSettings",CameraSettings)
        //console.log("**********>>>>>>", CameraSettings.width,CameraSettings.height)

        const videoEl = document.getElementById('camera_control_video')
        if(videoEl){
            document.body.removeChild(videoEl)
        }

        this.videoRef = document.createElement('video');

        this.videoRef.id = 'camera_control_video'

        this.videoRef.autoplay = true;
        this.videoRef.muted = true;
        this.videoRef.playsInline = true;
        this.videoRef.height = videoHeight;
        this.videoRef.width = videoWidth;
        this.videoRef.style.opacity = "0.0001";
        this.videoRef.style.position = "fixed";
        this.videoRef.style.pointerEvents = "none";
        //this.videoRef.style.bottom = "0";
        this.videoRef.style.top = "0";

        //this.videoRef.style.transform = "translateY(99%)";

        this.canvasRef = document.createElement('canvas');
        this.canvasRef.height=videoHeight;
        this.canvasRef.width=videoWidth;
        
        document.body.appendChild(this.videoRef);

        this.stream = stream;

        if (!this.isTesting) {
            this.videoRef.srcObject = this.stream;
            try {
                this.videoRef.play().catch(() => {});
            } catch {
    
            }
        }

    }

    setDebugVideo(url:string, videoSpeed:number=1, loop:boolean=true) {
        this.stopStream()

        const videoEl = document.getElementById('camera_control_video')
        if(videoEl){
            document.body.removeChild(videoEl)
        }

        this.videoRef = document.createElement('video');
        this.videoRef.id = 'camera_control_video'

        document.body.appendChild(this.videoRef);

        this.videoRef.autoplay = true;
        this.videoRef.muted = true;
        this.videoRef.loop = loop;
        console.log("setDebugVideo>>>",url,"loop=",loop)
        this.videoRef.crossOrigin = "Anonymous";
        this.videoRef.defaultPlaybackRate = videoSpeed;
        this.videoRef.playbackRate = videoSpeed;

        this.videoRef.playsInline = true;
        this.videoRef.height = videoHeight;
        this.videoRef.width = videoWidth;
        this.videoRef.style.opacity = "0.0001";
        this.videoRef.style.position = "fixed";
        this.videoRef.style.bottom = "0";
        this.videoRef.style.transform = "translateY(99%)";

        this.canvasRef = document.createElement('canvas');
        this.canvasRef.height=videoHeight;
        this.canvasRef.width=videoWidth;

        this.videoRef.src = url;
        try {
            this.videoRef.play().catch(() => {console.log("oh")});;
        } catch {
            
        }
        //this.stream = this.canvasRef.captureStream();
        this.stream = null;
    }

    getFrame() : ImageData|null {
        if (this.videoRef && this.canvasRef && this.stream) {
            const ctx = this.canvasRef.getContext('2d');
            if (ctx === null) {
                console.log("getFrame: no context")
                //this.active = false
                return null;
            }
            ctx.drawImage(this.videoRef, 0, 0, videoWidth, videoHeight);
            //this.active = true
            const res:ImageData = ctx.getImageData(0, 0, videoWidth, videoHeight);

            this.frameNum ++;
            if (this.frameNum < 5 ||
                (this.frameNum < 100 && this.frameNum % 10 === 1) ||
                (this.frameNum < 1000 && this.frameNum % 100 === 1) ||
                this.frameNum % 200 === 1) {
                    const n = controlSum(res)
                    //print("controlSum:",this.frameNum, n)
                    logger.logEvent("ImageDataControlSum",{n})
                }

                return res
        }
        //this.active = false
        return null;
    }

    async getFrameImageBitmap() : Promise<ImageBitmap|null> {
        if (this.videoRef) {
            
            //this.active = true
            try {
               const imageBitmap  = await createImageBitmap(this.videoRef);
               this.frameNum ++;
               return imageBitmap   
            } catch {
                //this.active = false
                return null;        
            }
        }
        //this.active = false
        return null;
    }

    async reconnectCamera() {
        if (this.stopped || this.reconnecting || document.visibilityState !== "visible") {
            return
        }

        this.reconnecting = true

        //console.log("CameraController.reconnectCamera !!! 1 ")
        if (this.reconnect) {
            //console.log("CameraController.reconnectCamera !!! 2 ")
            const stream = await this.reconnect()
            if (stream) {
                logger.event("CameraReconnected")
                console.log("CameraController.reconnectCamera got new stream!!! ")
                this.setCamera(stream, this.reconnect)
            }
        }

        this.reconnecting = false

    }

    getFrameHTMLVideoElement() : HTMLVideoElement | null {
        if (this.stopped || document.visibilityState !== "visible") {
            return null
        }

        if (this.videoRef?.paused || this.videoRef?.ended || this.videoRef?.readyState !== 4) {
            // console.log("CameraController getFrameHTMLVideoElement:",this.videoRef?.currentTime ,this.videoRef?.paused, this.videoRef?.ended ,this.videoRef?.readyState)

            if (this.videoRef?.paused || this.videoRef?.ended) {
                //console.log("CameraController stream is lost")                
                if (this.reconnect) {
                    //console.log("CameraController try to reconnect !!! ")
                    this.reconnectCamera()
                    //console.log("CameraController getFrameHTMLVideoElement *** ",this.videoRef?.currentTime ,this.videoRef?.paused, this.videoRef?.ended ,this.videoRef?.readyState)
                } 
            }
        }

        if (this.videoRef && (this.videoRef.readyState >= 2)) {
            return this.videoRef
        } else { 
            return null
        }
    }

    stopStream() {

        console.log("CameraController.stopStream")

        if ( this.stream ) {

            if (this.videoRef) {
                try {
                    this.videoRef.pause();
                    this.videoRef.src = "";
                    this.videoRef.srcObject = null;
                } catch {
                    
                }
            }

            this.stream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });

            this.stream = null;
        }
    }

    stop() {
        console.log("CameraController.stop")
        this.stopStream()
        this.stopped = true;
    }

    getStream(){
         return this.stream
    }

    get videoHeight() {
        return this.videoRef?.videoHeight
    }
    get videoWidth() {
        return this.videoRef?.videoWidth
    }

    handleVisibilityChange = () => {
        console.log("CameraController.handleVisibilityChange",document.visibilityState)
        logger.event("VisibilityChange-"+document.visibilityState)
        if (document.visibilityState === "visible") {
            this.reconnectCamera()
        } else {
            this.stopStream()
        }
    }

    constructor() {
        document.addEventListener("visibilitychange", this.handleVisibilityChange);
    }
}

export default CameraController;
