
const consoleLog = console.log

//consoleLog("%c console.log activated 1","background: cyan")

type LogFunction = (...params:any[]) => void;

function noLog(...params:any[]){
   
}

export function setConsoleLog(loud : boolean | LogFunction) {
    if (typeof loud == "boolean"){
        if (loud) {
            console.log = consoleLog
            //consoleLog("%c console.log is normal","background: cyan")

        } else {
            console.log = noLog
            //consoleLog("%c console.log is silented","background: cyan")

        }        
    } else {
        console.log = loud
        //consoleLog("%c console.log is catched","background: cyan")
    }
}

export function enableConsoleLog(){
    setConsoleLog(true)
}

export function disableConsoleLog(){
    setConsoleLog(false)
}



export default consoleLog
