
import { version } from "./version"

export function backend_log(data: any) {
  data.tfjsver = version
  data.level = "webgl-log"
  //console.log("===========================", data)
  const self_: any = self
  if (self_) {
    if (self_.kemtaiLogger) {
      //console.log("*********************", data)
      self_.kemtaiLogger.backend_log(data)
    }
  }
}

