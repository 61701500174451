import {Keypoints,PointName, fromArray} from "./keypoints"

export function encodeSkeletonsV3(skeletons:Keypoints[]) {
    //console.log("----------------------------------------encodeSkeletonsV3:")

    const points:{[key:string]:number} = {}
    const pointwiseEncoding :{[key:string]:number[]} = {}

    //get a list of all present points from all skeletons
    for (const skel of skeletons){
        for (const point of skel.points()) {
            points[point]=1;
        }
    }

    for (const point in points) {
        pointwiseEncoding[`${point}_x`] = []
        pointwiseEncoding[`${point}_y`] = []
    }
    for (const skel of skeletons){
        for (const point in points) {
            const p = skel.get(point as PointName)
            if (p) {
                pointwiseEncoding[`${point}_x`].push(p.ix)
                pointwiseEncoding[`${point}_y`].push(p.iy)
            } else {
                //console.log("missing point",point)
                pointwiseEncoding[`${point}_x`].push(-100)
                pointwiseEncoding[`${point}_y`].push(-100)
            }
        }
    }
    
    for (const coords in pointwiseEncoding) {
        const c0 = pointwiseEncoding[coords][0];
        const N = pointwiseEncoding[coords].length
        for(let i=N-1; i>0; i--) {
            pointwiseEncoding[coords][i] -= pointwiseEncoding[coords][i-1]
        }
    }

    return pointwiseEncoding
 }

function rsplit(str:string, sep:string, maxsplit:number) {
    var split = str.split(sep);
    return [ split.slice(0, -maxsplit).join(sep) ].concat(split.slice(-maxsplit)) 
}

export function decodeSkeletonsV3(encoded : any,resizeFactor:number=1) : Keypoints[] {
    const coords = Object.keys(encoded)
    //console.log(coords)
    const N = encoded[coords[0]].length
    for(const coord of coords) {
       for(let i=1; i<N; i++) {
            encoded[coord][i] += encoded[coord][i-1]
       }
    }

    const points:any = {}
    for (const coord of coords){
        const point = coord.replace(/_[xy]$/,"") 
        points[point] = 1
    }
    //console.log(Object.keys(points))

    const result : any[] = []
    for(let i=0; i<N; i++) {
        const kp:any = {}
        for (const point of Object.keys(points)) {
            const x = encoded[`${point}_x`][i] * resizeFactor
            const y = encoded[`${point}_y`][i] * resizeFactor
            if (x != -100) {
                kp[point]=[x,y]
            }
        }
        result.push(kp)
    }

    return fromArray(result)

}        