import { Keypoints, KeypointsArray,encodeSkeletonsV3, fixKeypoints } from "@kemtai/keypoints";
import {hasWebGL, gpu16bit, isWebGLEnabled, SystemStatus, FPSSmoother2, modelTest, modelConfig, MultiModel,  ModelInfo} from "@kemtai/skeleton-model"

import logger from '@kemtai/logger'
import { sleep, isMobile } from "@kemtai/utils";

import kemtapi from "./kemtapi"
import {Error,err,ok} from "./types"

import Config from "./config";
import consoleLog from "@kemtai/console-log";
import { logBattery } from "./utils/battery";

type ImageType = ImageBitmap|ImageData|HTMLVideoElement|null

// function print(msg:string) {
//     console.log(`%c${msg} @ ${logger.sessionTime()}s`,'background: #222; color: #00ff99' )
// }


if (isWebGLEnabled()) {
  console.log(Config.version,"WebGL:👍")
} else {
    consoleLog(`%cwebgl 😭 `, "color:red") 
}

function modelSelectionPolicy() {
    return Config._model || Config.modelSelectionPolicy 
}


interface SkeletonConsumer {
    resting : boolean
    getFrame() : Promise<ImageType>;
    onSkeleton(kp: KeypointsArray | null): void;
}

class SkeletonHandler {
    public consumer: SkeletonConsumer;
    count: number = 0;
    _info?: ModelInfo;
    coolingInterval = 0;
    

    //paused  : boolean = false
    stopped : boolean = false

    constructor(consumer: SkeletonConsumer) {
        this.consumer = consumer;
        this.setEconomyMode(false);
    }

    setEconomyMode(economyMode: boolean) {
        if (isMobile()) {
            if (economyMode) {
                this.coolingInterval = 100
            } else {
                this.coolingInterval = 20
            }
        } else {
            this.coolingInterval = 0
        }
    }

    
    async load() {
        return true
    }
    loadAllModels(policy: string = modelSelectionPolicy()) {}
    setPolicy(policy: string) { }

    async predict(frame: ImageType): Promise<KeypointsArray | null> {
        return null
    }


    async runPredictionLoop() {
        while (true) {
            // if (this.paused) {
            //     await sleep(100);
            //     continue
            // }
            if (this.stopped) {
                 return
            }
            this.count++
            const frame = await this.consumer.getFrame()
            let kp : KeypointsArray | null = null;
            if (this.consumer.resting){
                await sleep(50)
            } else {
                kp = await this.predict(frame)
            }
            this.consumer.onSkeleton(kp)
            //console.log(" frame .... ",this.coolingInterval)
            await sleep(this.coolingInterval)
        }
    }

    // pause() {
    //     this.paused = true
    // }

    // resume() {
    //     this.paused = false
    // }

    get info() {
        return this._info
    }

}


class SimpleSkeletonHandler extends SkeletonHandler {
    mm: MultiModel
    predicted:boolean = false
    constructor(consumer: SkeletonConsumer) {
        super(consumer)
        this.mm = new MultiModel();
        this.setPolicy(modelSelectionPolicy())
    }

    async load() {
        this.mm.load(modelSelectionPolicy())
        await this.mm.isReady()
        return this.mm.ready
    }

    async warmUp() {
        const ok = await this.mm.warmUp(modelSelectionPolicy())
        return ok
    }

    async loadAllModels(policy: string = modelSelectionPolicy()) {
        const ok = await this.mm.loadAllModels(policy)
        return ok
    }

    setPolicy(policy: string) {
        //console.log("SimpleSkeletonHandler setPolicy:",policy)
        this.mm.setPolicy(policy)
    }

    async predict(frame: ImageType): Promise<KeypointsArray | null> {
        const res = await this.mm.predictImage(frame!)
        if (res)
            if (! this.predicted) {
                this.predicted = true
                //console.log(`first frame predicted @ ${logger.sessionTime()} by ${this.mm.currentModel}`)
                logger.event('firstFrameSkeleton')
                this.mm.currentModel
            }
        return res

    }

    get info() {
        return this.mm.info
    }
}




class FakeSkeletonHandler extends SkeletonHandler {
    keypoints: KeypointsArray[] | null = null
    current: number = 0

    constructor(consumer: SkeletonConsumer, kps? : Keypoints[]) {
        super(consumer)
        //console.log("FakeSkeletonHandler constructor",kps)
        if (kps) {
            this.keypoints = kps.map(KeypointsArray.fromKeypoints)
        } else {
            const _data = new Float32Array([317, 120, 317, 101, 317, 135, 318, 154, 322, 117, 311, 117, 332, 125, 304, 125, 345, 165, 291, 166, 355, 214, 283, 216, 364, 253, 277, 263, 334, 252, 302, 252, 340, 320, 293, 320, 344, 390, 290, 390, 350, 407, 288, 407, 336, 401, 297, 401, 369, 278, 273, 288, 364, 269, 280, 281, NaN, NaN, 318, 165, 319, 187, 318, 210, 318, 235, 318, 257, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN])
            const kp = new KeypointsArray(_data)
            this.keypoints = [kp]
            this.current = 0
        }
    }

    async load() {
        return true
    }

    async warmUp() {
        return true
    }

    async loadAllModels(policy: string = modelSelectionPolicy()) {
        return true
    }


    async predict(frame: ImageType): Promise<KeypointsArray | null> {
        //const _data = new Float32Array([317, 120, 317, 101, 317, 135, 318, 154, 322, 117, 311, 117, 332, 125, 304, 125, 345, 165, 291, 166, 355, 214, 283, 216, 364, 253, 277, 263, 334, 252, 302, 252, 340, 320, 293, 320, 344, 390, 290, 390, 350, 407, 288, 407, 336, 401, 297, 401, 369, 278, 273, 288, 364, 269, 280, 281, NaN, NaN, 318, 165, 319, 187, 318, 210, 318, 235, 318, 257, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN])
        //const kp = new KeypointsArray(_data)
        const kp = this.keypoints![this.current]
        this.current = (this.current + 1) % this.keypoints!.length
        await sleep(100)
        return kp
    }

    get info() {
        return  {
            name : "fake",
            time : 25,
            rawtime : 25,
            fps  : 50,
            loading : false,
            stats :  {"fake":{count:1,fps:50}}
        }   
    }
}


/*
class WorkerSkeletonHandler extends SkeletonHandler {
    promiseWorker: PromiseWorker

    constructor(consumer: SkeletonConsumer) {
        super(consumer)

        //const worker = new (skeletonMMWorker as any)();
        //const worker = new Worker(`${Config.workerBaseUrl}/skeleton-mm.worker.js`)
        const worker = getSkeletonMMWorker();
        this.promiseWorker = new PromiseWorker(worker);
        //this.wcall("userids", getSessionIds())
        //console.log(JSON.stringify(Config))
        this.wcall("config", JSON.parse(JSON.stringify(Config)))

        this.setPolicy(modelSelectionPolicy())
    }

    async load() {
        const loading_error = await this.wcall("error")
        return !loading_error
    }

    async call(command: string, data: any = null) {
        return this.promiseWorker.postMessage({ command, data })
    }
    async wcall(command: string, data: any = null) {
        return await this.call(command, data)
    }


    setPolicy(policy: string) {
        return this.call("setPolicy", policy)
    }

    async loadAllModels(policy: string = modelSelectionPolicy()) {
        return this.wcall("loadAllModels",policy)
    }

    async predict(frame: ImageType): Promise<KeypointsArray | null> {
        const msg = await this.call("predict", frame)
        if (msg) {
            const [res, info] = msg
            this._info = info
            //console.log(res)
            return new KeypointsArray(res as Float32Array)
            //return Keypoints.fromMap(res)
        } else {
            return null
        }
    }

}


async function getWorkerWebGLStatus(): Promise<boolean> {

    let status: boolean | undefined = undefined
    //let worker = new (skeletonWorker as any)();
    //let worker = new Worker(`${Config.workerBaseUrl}/skeleton.worker.js`)
    const worker = getSkeletonWorker()

    const handleMessageFromWorker = async (msg: MessageEvent) => {
        const [command, data] = msg.data
        switch (command) {
            case "hasWebGL": {
                status = data
                worker.terminate();
                break
            }
            default: {
                console.log("unexpected message", msg.data)
            }
        }
    }

    worker.addEventListener('message', handleMessageFromWorker);
    worker.postMessage(["hasWebGL"]);
    await sleep(10)

    while (status === undefined) {
        await sleep(10)
    }
    return status
}
*/

type KeypointsMetadata = {
    t: number,
    model: string,
    fps: number,
    n: number
}

class KeypointsHistory {
    private kp: Keypoints[] = []
    private meta: KeypointsMetadata[] = []
    readonly skeletonEncodingVersion = "v3"

    init() {
        this.kp = []
        this.meta = []
    }

    push(kp: Keypoints, meta:KeypointsMetadata) {
        this.kp.push(kp)
        this.meta.push(meta)
    }

    get length() {
        return this.kp.length
    }

    stringify() {
        return JSON.stringify({
                    kp:     encodeSkeletonsV3(this.kp), 
                    meta:   this.meta,
                    skeleton_encoding : this.skeletonEncodingVersion
                })
    }

}

/*
class TimeValueStabilazer {
    value = false
    minTime = 10.
    start = 0

    constructor(value = false, minTime = 10.) {
        this.value = value
        this.minTime = minTime
    }

    set(value:boolean) {
        if (value === this.value) {
            this.start = 0
        } else {
            if (this.start) {
                const age = (Date.now() - this.start)/1000.
                //console.log(`==== ${this.value} ==> ${value} | ${age} / ${this.minTime} `)
                if (age > this.minTime) {
                    this.value = value
                    this.start = 0
                }
            } else {
                this.start = Date.now()
            }
        }
        return this.value
    }

    reset(value:boolean) {
        this.value = value
        this.start = 0
    }
}
*/

/*
class LowFPSDetector {
    lowFPS: boolean = false
    v : TimeValueStabilazer

    constructor(public threshold = 6., public minTime = 20.) {
        this.v = new TimeValueStabilazer(false,minTime)
    }

    onCameraOff() {
        // reset metrics
        this.lowFPS = false
        this.v.reset(false)
    }

    onFrame(fps: FPSSmoother) {
        if (fps.count > 10 && fps.FPS < this.threshold) {
            this.v.set(true)
        } else {
            this.v.set(false)
        }
        this.lowFPS = this.v.value
        return this.lowFPS
    }
}

*/


function logSystemState(modelInfo: ModelInfo) {
    let logRecord: any = { event: "systemState", level: "log" }

    //FPS stats
    for (let m of Object.keys(modelInfo.stats)) {
        logRecord[`model_stats:${m}:count`] = modelInfo.stats[m].count
        logRecord[`model_stats:${m}:fps`] = modelInfo.stats[m].fps
    }

    // Memory
    let p: any = performance as any //not standard Chrome extention; not defined in typescript
    if (p.memory) {
        //console.log("Memory:", p.memory)
        logRecord["usedJSHeapSize"] = p.memory.usedJSHeapSize
    }

    logger.backendLog(logRecord)
}


function logFPS(modelInfo:ModelInfo, frameNum:number) {
    /*
    if (frameNum % 1000 === 1) {
        print(`model:${modelInfo.name} FPS:${Math.round(modelInfo.fps, 1)} `)
    }
    */
    // log FPS once at 5 min
    if (frameNum % (15 * 60 * 5) === 50) {  // 15fps * 60s * 5m = 72000
        logger.event("FPS", { model: modelInfo.name, FPS: Math.round(modelInfo.fps) })
        //console.log(`model:${modelInfo.name} FPS:${round(modelInfo.fps, 1)} `)       
        logBattery()
    }
    // log FPS every ~3 sec
    if (frameNum % (15 * 3) === 1) {
        logger.backendLog({ event: "fps", level: "log", model: modelInfo.name, fps: Math.round(modelInfo.fps) })
    }

    // extensive FPS record every ~1 min
    if (frameNum % (15 * 60) === 1) {
        logSystemState(modelInfo)
    }
}


class FPSHandler {
    FPS: FPSSmoother2;
    //lowFPSDetector: LowFPSDetector;
    private _fpsWasOK: boolean = false;//window.localStorage.getItem("fpsWasOK") === "true"
    controller : SkeletonController
    //fpsTooLowDisabled: boolean = false
    //fpsTooLowTemporaryDisabled: boolean = false

    constructor(controller:SkeletonController) {
        this.FPS = new FPSSmoother2();
        this.controller = controller
    }

    onCameraOff() {
        this.FPS.reset()
    }

    onSkeleton() {
        const fps = this.FPS.onPredict()

        if (this.FPS.count > 50 && fps > 15) {
            this.fpsWasOK = true
        }

    }


    get fpsWasOK() {
        return this._fpsWasOK
    }
    set fpsWasOK(v: boolean) {
        if (this._fpsWasOK) {
            return
        }
        if (v) {
            this._fpsWasOK = true
            window.localStorage.setItem("fpsWasOK", "true")
            logger.event("fpsWasOK")
        }
    }

}


class SkeletonController implements SkeletonConsumer {
    protected skeletonHandler: SkeletonHandler;
    fpsHandler : FPSHandler
    systemStatus: SystemStatus | undefined = undefined;
    currentSkeleton: Keypoints | null = null;
    fpsTooLow: boolean = false
    frame: ImageType| null = null;
    frameNum: number = 0;
    frameTime: number = Date.now()
    modelInfo?: ModelInfo;

    private skeletonHistory: KeypointsHistory = new KeypointsHistory()
    noSkeletonLogging : boolean = false
    listeners : {key:string, action:any, disposed:boolean}[] = []
    modelError: boolean = false;
    resting : boolean = false
    //stabilazer = new Stabilazer()

    constructor() {
        this.skeletonHandler = new SkeletonHandler(this);
        this.fpsHandler = new FPSHandler(this);
        //this.noSkeletonLogging = isMobile();
    }

    async warmUp() {
        const skeletonHandler = new SimpleSkeletonHandler(this)
        await skeletonHandler.warmUp()
    }

    setEconomyMode(economyMode: boolean) {
        this.skeletonHandler.setEconomyMode(economyMode)
    }

    rest(resting : boolean) {
        this.resting = resting
        this.sendSkeletonLog()
        //console.log("SKELETON RESTING: ", resting)
    }

    stop() {
        this.onStopCamera()
        this.skeletonHandler.stopped = true
    }

    start() {
        this.skeletonHandler.stopped = false;
    }
    
    setFakeSkeleton(kps? : Keypoints[]) {
        this.skeletonHandler = new FakeSkeletonHandler(this,kps)
    }

    async startWorker() {
        /*
        let webGLinWorker = false // await getWorkerWebGLStatus()
        if (webGLinWorker) {
            this.skeletonHandler = new WorkerSkeletonHandler(this)
        } else {
            this.skeletonHandler = new SimpleSkeletonHandler(this)
        }
        */
        if (Config.fakeSkeleton){
            this.skeletonHandler = new FakeSkeletonHandler(this)
        } else {
            this.skeletonHandler = new SimpleSkeletonHandler(this)
        }

        const succ = await this.skeletonHandler.load()
        console.log(`%c Models are loaded @ ${logger.sessionTime()}s success=${succ}`,'background: #222; color: #bada55' )
        //print("skeleton strated")
        logger.event("Model loaded*")
        logger.event("AllModelsLoaded")

        if (!succ) {
            return err(Error.ModelLoadingFailed)
        }

        await this.testModel()

        this.skeletonHandler.runPredictionLoop()

        return ok()
        
    }

    async testModel() {
        const image = await modelTest.getTestImage()   // await convertURIToImageData(testImage)
        const kp = await this.skeletonHandler.predict(image)
        if (!kp) {
            logger.logEvent("testModel KP is null")
            return null
        } else {
            const n = kp.keypoints.points().length
            //console.log(`testModel ${this.skeletonHandler.info?.name} NPOINTS=${n}`)
            logger.logEvent("testModel", {npoints:n})
            //print(`testModel ${n}`)

            if (! modelTest.check(n)) {
                if (!this.modelError) {
                   logger.logEvent("modelError", {npoints:n})
                   consoleLog(`%ctestModel failed n=${n} ${this.skeletonHandler.info?.name}`,'background-color:red')    

                   for(let i=0; i < 3 ; i++) {
                        const kp = await this.skeletonHandler.predict(image)
                        const n = kp!.keypoints.points().length
                        console.log(`n=${n}`)
                        if (modelTest.check(n)){
                            return
                        }
                   }     
                }
                this.modelError = true
            }

            return n
        }
    }

    async checkSystemCompatability() {    
        if (typeof window === "undefined"  ) {
            logger.event("kemtapi-check-NodeJSNotSupported",{level:"kemtapi"})
            return err(Error.NodeJSNotSupported)
        }

        // camera
        if (!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)) {
            logger.event("kemtapi-check-MediaDevicesNotSupported",{level:"kemtapi"})
            return err(Error.MediaDevicesNotSupported)
        }

        // tfjs webgl backend
        if (!isWebGLEnabled()) {
            // webgl
            if (!hasWebGL()) {
                logger.event("kemtapi-check-WebGLDisabled",{level:"kemtapi"})
                return err(Error.WebGLDisabled)
            } else {
                logger.event("kemtapi-check-InternalError",{level:"kemtapi"})
               return err(Error.InternalError)
            }
        }

        //gpu16bit
        if (gpu16bit()) {
            logger.event("kemtapi-check-gpu16bit",{level:"kemtapi"})
            return err(Error.InsufficientHardware)
        }

        // speed
        const fpsWasOK : boolean = window.localStorage.getItem("fpsWasOK") === "true"      
        if (fpsWasOK) {
            logger.event("kemtapi-check-ok",{level:"kemtapi",fpsWasOK})
            return ok()    
        }

        const FPS = await modelConfig.checkFPSNew()
        if (FPS < 7 ) {
            return err(Error.InsufficientHardware)
        }

        logger.event("kemtapi-check-ok",{level:"kemtapi",FPS,fpsWasOK})
        return ok()
    }


    async getFrame() :Promise<HTMLVideoElement|null> {
        if (Config._maxFPS) {
            // slow down to enforce maxFPS
            //http://localhost:3000/home?model=a7&maxFPS=9
            const delay    = Date.now() - this.frameTime // actual frame time
            const minDelay = 1000 / Config._maxFPS       // min frame time to enforce maxFPS
            if (delay < minDelay){
                await sleep(minDelay-delay)
            }   
        }
        
        let frame = await kemtapi.camera.getFrameHTMLVideoElement()
        while (!frame) {
            this.fpsHandler.onCameraOff()
            await sleep(100)
            frame = await kemtapi.camera.getFrameHTMLVideoElement()
        }
        this.frame = frame
        this.frameTime = Date.now()
        return frame
    }

    setModelPolicy(policy: string = modelSelectionPolicy()) {
        this.skeletonHandler.setPolicy(policy)
    }

    async loadAllModels(policy: string = modelSelectionPolicy()) {
        await this.skeletonHandler.loadAllModels(policy)
    }

    saveSkeleton(kp: Keypoints) {
        if (this.noSkeletonLogging) {
            return
        }

        this.skeletonHistory.push(kp,{
                t: logger.sessionTime(),
                model: this.modelInfo!.name,
                fps: Math.round(this.modelInfo!.fps),
                n: this.frameNum
        })

        if (this.skeletonHistory.length > 100) {
            this.logSkeleton()
        }
    }

    logSkeleton() { 
        if (this.noSkeletonLogging) {
            return
        }
        this.sendSkeletonLog()
    }
    sendSkeletonLog() { 
        if (this.skeletonHistory.length > 0) {
            logger.backendLog({
                level: "skeleton",
                event: "skeleton",
                skeleton: this.skeletonHistory.stringify(),
                length: this.skeletonHistory.length,
                frameNum: this.frameNum,
                skeleton_encoding : this.skeletonHistory.skeletonEncodingVersion
            })
            this.skeletonHistory.init()
        }
    }


    async onSkeleton(kp: KeypointsArray | null) {
        if (kp === null) {
            this.currentSkeleton = null;
        } else {
            // console.log("got skeleton!")
            //this.currentSkeleton = this.stabilazer.process(fixKeypoints(kp.keypoints));
            this.currentSkeleton = fixKeypoints(kp.keypoints);
            this.frameNum++;
            if (this.frameNum % 300 == 10) {
                this.testModel()
            }
            this.modelInfo = this.skeletonHandler.info
            this.fpsHandler.onSkeleton()
            if (this.modelInfo) {
                logFPS(this.modelInfo, this.frameNum)
            }

            this.saveSkeleton(this.currentSkeleton)
            
        }
        this.dispatch(this.currentSkeleton, this.frame)
    }


    onStopCamera(){
        console.log("=======onStopCamera")
        this.logSkeleton()
    }

    register(action:any, key:string) {
        this.listeners.push({key, action, disposed:false})
        return (()=>{ this.dispose(key)})
    }

    dispatch(kp: Keypoints | null, frame:ImageType|null) {
        for(let listener of this.listeners) {
            if (!listener.disposed) {
                listener.action(kp,frame)
            }
        }
    }

    dispose(key:string){
        for (let listener of this.listeners){
            if (listener.key === key) {
                listener.disposed = true
            }
        }
        this.listeners = this.listeners.filter( (listener)=> {return listener.key !== key})
    }


}


export default SkeletonController;


export class FakeSkeletonController extends SkeletonController {

    constructor(kps? : Keypoints[]) {
        super()

        //console.log("FakeSkeletonController constructor",kps)
        this.skeletonHandler = new FakeSkeletonHandler(this, kps)
    }
    async startWorker() {
        //console.log("FakeSkeletonController startWorker")
        this.skeletonHandler.runPredictionLoop()
        return ok()
    }

    
    async getFrame()  {
        return null
    }
    
    
    async onSkeleton(kp: KeypointsArray | null) {
        this.currentSkeleton = null;
        this.dispatch(null, null)
    }
    
}

export class FakeSkeletonController2 extends SkeletonController {

    constructor(kps? : Keypoints[]) {
        super()
        this.skeletonHandler = new FakeSkeletonHandler(this, kps)
    }
    async startWorker() {
        this.skeletonHandler.runPredictionLoop()
        return ok()
    }

}

