import testImage from './testImage'
import { convertURIToImageData } from './utils'


class ModelTest {
    testImage : ImageData | null = null
    private readonly nPoints = 102; // 38;


    async getTestImage() {
        if (!this.testImage) {
            this.testImage = await convertURIToImageData(testImage)
            return this.testImage
        }
        return this.testImage
    }

    check(n:number) {
        //console.log("ModelTest check",n);
        return n >= this.nPoints - 1

        return n == this.nPoints
    }
}

const modelTest = new ModelTest()
export default modelTest;
