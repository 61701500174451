import {Keypoints, PointName } from "@kemtai/keypoints";

type RGB        = [number,number,number]

function col (v:string|RGB) {
  if (typeof(v) === "string"){
      return  v
  } else {
      return `rgb(${v[0]},${v[1]},${v[2]})`
  }
}

const  back  = "rgb(75,0,130)"
const  front = "rgb(70,130,180)"
const left   = "rgb(0, 255, 0)"
const right  = "rgb(0, 0, 255)"
const center = col([255,0,0])

const point_color = new Map<PointName,string>([
  ['nose', "rgb(255, 0, 0)"],
  ['neck', "rgb(255, 85, 0)"],
  ["top_of_the_head", "rgb(255, 85, 0)"],
  ["chin", "rgb(255, 85, 0)"],
  ['right_elbow', "rgb(255, 255, 0)"],
  ['left_elbow', "rgb(0, 255, 0)"],
  ['right_wrist', "rgb(170, 255, 0)"],
  ['left_wrist', "rgb(0, 255, 85)"],
  ['left_shoulder', "rgb(85, 255, 0)"],
  ['right_shoulder', "rgb(255, 170, 0)"],
  ['left_hip', "rgb(0, 85, 255)"],
  ['right_hip', "rgb(0, 255, 170)"],
  ['left_knee', "rgb(0, 0, 255)"],
  ['right_knee', "rgb(0, 255, 255)"],
  ['left_ankle', "rgb(85, 0, 255)"],
  ['right_ankle', "rgb(0, 170, 255)"],
  ['left_eye', "rgb(255, 0, 255)"],
  ['right_eye', "rgb(170, 0, 255)"],
  ['left_ear', "rgb(255, 0, 85)"],
  ['right_ear', "rgb(255, 0, 170)"],
  ["left_thumb",left],
  ["right_thumb",right],
  ["left_finger",left],
  ["right_finger",right],
  ["left_bigtoe","rgb( 85, 0,255)"],
  ["right_bigtoe","rgb( 85, 0,255)"],
  ["left_heel","rgb( 85, 0,255)"],
  ["right_heel","rgb( 85, 0,255)"],
  ["back_1", back],
  ["back_2", back],
  ["back_3", back],
  ["back_4", back],
  ["back_5", back],

  ["front_1", front],
  ["front_2", front],
  ["front_3", front],
  ["front_4", front],
  ["front_5", front],


  ["left_shoulder_hand", left],
  ["right_shoulder_hand", right], 
  ["left_shoulder_neck", left],
  ["right_shoulder_neck", right],
  ["left_under_ear", left],
  ["right_under_ear", right], 
  ["neck_top", center], 
  ["back_neck_top",col([200,128,128])],


  
  

])
const left_hand:PointName[] = [ 'left_thumb1', 'left_thumb2', 'left_thumb3', 'left_thumb4', 'left_forefinger1', 'left_forefinger2', 'left_forefinger3', 'left_forefinger4', 'left_middle_finger1', 'left_middle_finger2', 'left_middle_finger3', 'left_middle_finger4', 'left_ring_finger1', 'left_ring_finger2', 'left_ring_finger3', 'left_ring_finger4', 'left_pinky_finger1', 'left_pinky_finger2', 'left_pinky_finger3', 'left_pinky_finger4']
const right_hand:PointName[] =['right_thumb1', 'right_thumb2', 'right_thumb3', 'right_thumb4', 'right_forefinger1', 'right_forefinger2', 'right_forefinger3', 'right_forefinger4', 'right_middle_finger1', 'right_middle_finger2', 'right_middle_finger3', 'right_middle_finger4', 'right_ring_finger1', 'right_ring_finger2', 'right_ring_finger3', 'right_ring_finger4', 'right_pinky_finger1', 'right_pinky_finger2', 'right_pinky_finger3', 'right_pinky_finger4']
for(const p of left_hand){
  point_color.set(p,left)
}
for(const p of right_hand){
  point_color.set(p,right)
}



type point_pair = [PointName,PointName]
type rgb = [number,number,number]
type pair_color_type = [point_pair,rgb]
const  back_arr:rgb = [5,0,130]
const  front_arr:rgb = [70,130,180]
const gold:rgb  = [255, 215, 0]
const thumb_color:rgb = [0,255,0]
const forefinger_color:rgb = [100,255,255]
const middle_finger_color:rgb = [0,0,255]
const ring_finger_color:rgb = [255,255,100]
const pinky_finger_color:rgb = [255,100,100]

const pair_color : pair_color_type[] = [
  [['neck', 'right_shoulder'], [255, 0, 0]],
  [['neck', 'left_shoulder'], [255, 85, 0]],
  [['right_shoulder', 'right_elbow'], [255, 170, 0]],
  [['right_elbow', 'right_wrist'], [255, 255, 0]],
  [['left_shoulder', 'left_elbow'], [170, 255, 0]],
  [['left_elbow', 'left_wrist'], [85, 255, 0]],
  [['right_hip', 'right_knee'], [0, 255, 85]],
  [['right_knee', 'right_ankle'], [0, 255, 170]],
  [['left_hip', 'left_knee'], [0, 170, 255]],
  [['left_knee', 'left_ankle'], [0, 85, 255]],
  [['neck', 'chin'], [0, 0, 255]],
  [['chin', 'nose'], [0, 100, 255]],
  [['nose', 'right_eye'], [85, 0, 255]],
  [['right_eye', 'right_ear'], [170, 0, 255]],
  [['nose', 'left_eye'], [255, 0, 255]],
  [['left_eye', 'left_ear'], [255, 0, 170]],

//   [['left_wrist', "left_thumb"],[255, 0, 85]],
//   [['left_wrist', "left_finger"],[255, 0, 85]],
//   [['right_wrist', "right_thumb"],[255, 0, 85]],
//   [['right_wrist', "right_finger"],[255, 0, 85]],

  [['right_ankle', "right_bigtoe"],[ 85, 0,255]],
  [['right_ankle', "right_heel"],[ 85, 0,255]],
  [['left_ankle', "left_bigtoe"],[ 85, 0,255]],
  [['left_ankle', "left_heel"],[ 85, 0,255]],

  //[["neck","spinal_cord_1"],[0,255,0]],
  //[["spinal_cord_1","spinal_cord_2"],[0,255,50]],
  //[["spinal_cord_2","spinal_cord_3"],[0,255,100]],
  //[["spinal_cord_3","spinal_cord_4"],[0,255,150]],
  //[["spinal_cord_4","spinal_cord_5"],[0,255,200]],
  //[["left_hip","spinal_cord_5"],[0,0,200]],
  //[["right_hip","spinal_cord_5"],[0,0,200]],


  [["neck",   "front_1"],front_arr],
  [["front_1","front_2"],front_arr],
  [["front_2","front_3"],front_arr],
  [["front_3","front_4"],front_arr],
  [["front_4","front_5"],front_arr],


  [["neck_back",   "back_1"],back_arr],
  [["back_1","back_2"],back_arr],
  [["back_2","back_3"],back_arr],
  [["back_3","back_4"],back_arr],
  [["back_4","back_5"],back_arr],

  [["left_shoulder_neck","left_shoulder_hand"], gold],
  [["right_shoulder_neck","right_shoulder_hand"], gold],
  [["right_under_ear","right_shoulder_neck"], gold],
  [["left_under_ear","left_shoulder_neck"], gold],

  [["left_wrist", "left_thumb1"], thumb_color], 
  [["left_thumb1", "left_thumb2"], thumb_color], 
  [["left_thumb2", "left_thumb3"], thumb_color], 
  [["left_thumb3", "left_thumb4"], thumb_color], 

  [["left_wrist", "left_forefinger1"], forefinger_color], 
  [["left_forefinger1", "left_forefinger2"], forefinger_color],  
  [["left_forefinger2", "left_forefinger3"], forefinger_color],  
  [["left_forefinger3", "left_forefinger4"], forefinger_color],  

  [["left_wrist", "left_middle_finger1"], middle_finger_color],  
  [["left_middle_finger1", "left_middle_finger2"], middle_finger_color],  
  [["left_middle_finger2", "left_middle_finger3"], middle_finger_color],  
  [["left_middle_finger3", "left_middle_finger4"], middle_finger_color],  

  [["left_wrist", "left_ring_finger1"], ring_finger_color],  
  [["left_ring_finger1", "left_ring_finger2"], ring_finger_color],  
  [["left_ring_finger2", "left_ring_finger3"], ring_finger_color],  
  [["left_ring_finger3", "left_ring_finger4"], ring_finger_color],  

  [["left_wrist", "left_pinky_finger1"], pinky_finger_color],  
  [["left_pinky_finger1", "left_pinky_finger2"], pinky_finger_color],  
  [["left_pinky_finger2", "left_pinky_finger3"], pinky_finger_color],  
  [["left_pinky_finger3", "left_pinky_finger4"], pinky_finger_color],  

  [["right_wrist", "right_thumb1"], thumb_color],  
  [["right_thumb1", "right_thumb2"], thumb_color],  
  [["right_thumb2", "right_thumb3"], thumb_color],  
  [["right_thumb3", "right_thumb4"], thumb_color],  

  [["right_wrist", "right_forefinger1"], forefinger_color],  
  [["right_forefinger1", "right_forefinger2"], forefinger_color],  
  [["right_forefinger2", "right_forefinger3"], forefinger_color],  
  [["right_forefinger3", "right_forefinger4"], forefinger_color],  

  [["right_wrist", "right_middle_finger1"], middle_finger_color],  
  [["right_middle_finger1", "right_middle_finger2"], middle_finger_color],  
  [["right_middle_finger2", "right_middle_finger3"], middle_finger_color],  
  [["right_middle_finger3", "right_middle_finger4"], middle_finger_color],  

  [["right_wrist", "right_ring_finger1"], ring_finger_color],  
  [["right_ring_finger1", "right_ring_finger2"], ring_finger_color],  
  [["right_ring_finger2", "right_ring_finger3"], ring_finger_color],  
  [["right_ring_finger3", "right_ring_finger4"], ring_finger_color],  

  [["right_wrist", "right_pinky_finger1"], pinky_finger_color],  
  [["right_pinky_finger1", "right_pinky_finger2"], pinky_finger_color],  
  [["right_pinky_finger2", "right_pinky_finger3"], pinky_finger_color],  
  [["right_pinky_finger3", "right_pinky_finger4"], pinky_finger_color],  

 
]


export function drawSkeleton(ctx:CanvasRenderingContext2D, kp:Keypoints)
{
  if (kp == null) {
    return
  }
  //console.log(kp.left_wrist,kp.right_wrist);

  ctx.lineWidth = 5;

  for (let point of kp.points()) {
    ctx.beginPath();
    let c = point_color.get(point) || "rgb(255,0,0)"
    ctx.arc(kp.get(point)!.x, kp.get(point)!.y, 3 , 0, 2 * Math.PI, false);
    ctx.strokeStyle = c;
    ctx.stroke();
  }

  for (let [pair,c] of pair_color) {
    let [p1,p2] = pair
    if (kp.hasPoint(p1) && kp.hasPoint(p2)) {
      var cc;
      if (c) {
        cc = `rgb(${c[0]},${c[1]},${c[2]})`
      } else {
        cc = "rgb(255,0,0)"
      }

      ctx.beginPath();
      ctx.strokeStyle = cc;
      ctx.moveTo(kp._getX(p1), kp._getY(p1));
      ctx.lineTo(kp._getX(p2), kp._getY(p2));
      ctx.stroke();
    }
  }
}

/*
export function drawBox(ctx:CanvasRenderingContext2D, bb:BBox){
    ctx.beginPath();
    ctx.rect(bb.x1,bb.x2,bb.width, bb.height);
    ctx.stroke();
}
*/

export function drawFrame(canvas: HTMLCanvasElement|null,
                          img : ImageData|ImageBitmap|null,
                          alpha:number = 1) : CanvasRenderingContext2D | null {
  if (!canvas || !img) {
    return null
  }

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return null
  }

  if(alpha === 0){

    ctx.globalAlpha = 0;
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.globalAlpha = 1;

  } else if(alpha < 1){

    ctx.globalAlpha = 0;
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.globalAlpha = alpha;
    if (img instanceof ImageData) {
      ctx.putImageData(img, 0, 0);
    } else {
      ctx.drawImage(img, 0, 0);
    }
    ctx.globalAlpha = 1;

  } else {
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    if (img instanceof ImageData) {
      ctx.putImageData(img, 0, 0);
    } else {
      ctx.drawImage(img, 0, 0);
    }

  }
  return ctx
}


export function drawFrameSkeleton(canvas: HTMLCanvasElement|null,
                                  img : ImageData|ImageBitmap|null,
                                  kp:Keypoints|null) : CanvasRenderingContext2D | null {
  const ctx = drawFrame(canvas, img)
  if (!ctx) {
    return null
  }
  if (kp) {
    drawSkeleton(ctx,kp);
  }
  return ctx
}



export default drawSkeleton
