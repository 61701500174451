import CameraController from "./CameraController"
import SkeletonController,{FakeSkeletonController,FakeSkeletonController2} from "./SkeletonController"

import  Config  from "./config";
import logger from '@kemtai/logger'
import { KemtAPIInitParams, ok} from "./types";
import { Keypoints } from "@kemtai/keypoints";



declare global {
    interface Window {
        _kemtapi: any;
    }
}

class KemtAPI  {
    camera : CameraController
    skeleton : SkeletonController

    private modelsLoaded: boolean= false;
    
    constructor() {
        this.camera = new CameraController()
        this.skeleton = new SkeletonController()
    }

    static getInstance() : KemtAPI {
        if (!self._kemtapi) {
            self._kemtapi = new KemtAPI();
        }

        return self._kemtapi;
    }

    async init(params : KemtAPIInitParams) {
        
        Config.init(params)


        logger.init({ ...Config, url:`${Config.logServerUrl}/log/` });


   

        if (typeof window !== "undefined") {
            logger.event("init",{level:"kemtapi",
                                referrer: document.referrer,
                                browser:  window.navigator.userAgent,
                                url:window.location.href})
        }

        return ok()
    }

    setParams(params : {[key:string]:any}) {
        Config.setParams(params);
    }

    setInputVideoStream(stream: MediaStream, reconnect:any = null) {
        logger.event("setInputVideoStream",{level:"kemtapi"})
        this.camera.setCamera(stream, reconnect)
    }

    releaseVideoStream( ) {
        logger.event("releaseVideoStream",{level:"kemtapi"})
        this.camera.stop()
    }

    stop(){
        logger.event("kemtapi.stop",{level:"kemtapi"})
        this.camera.stop()
        this.skeleton.stop()
    }

    reset() {
        this.skeleton.start();
        this.modelsLoaded = false;
    }



    registerFrameSkeletonCallback(callback:Function, id:string){
        logger.event("registerFrameSkeletonCallback",{level:"kemtapi"})
        return this.skeleton.register(callback,id)
    }

    stopFrameSkeletonCallback(id:string){
        logger.event("stopFrameSkeletonCallback",{level:"kemtapi"})
        return this.skeleton.dispose(id)
    }


    /*
        {success:true}
        {success:false, error: "  " }
    */
    async checkSystemCompatibility() {
        logger.event("checkSystemCompatibility",{level:"kemtapi"})
        return this.skeleton.checkSystemCompatability()
    }


    async  loadModels() {
        logger.event("loadModels",{level:"kemtapi"})

        if (this.modelsLoaded) {
            return ok();
        }
        const status = await this.skeleton.startWorker()
        if (status.success) {
            this.modelsLoaded = true
        }
        return status

    }

    
    pauseSkeleton(){
        this.skeleton.rest(true)
    }
    resumeSkeleton(){
        this.skeleton.rest(false)
    }

    fakeSkeletonMode(kps? : Keypoints[]) {
        this.skeleton = new FakeSkeletonController(kps)
        this.skeleton.startWorker()
    }
    fakeSkeletonMode2(kps? : Keypoints[]) {
        this.skeleton = new FakeSkeletonController2(kps)
        this.skeleton.startWorker()
    }

    restoreSkeletonController() {
        this.skeleton = new SkeletonController();
        this.skeleton.startWorker();
    }

    enableTestingMode() {
        this.camera.isTesting = true;
    }

}

const kemtapi = KemtAPI.getInstance()
export default kemtapi;
