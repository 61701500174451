
import logger from '@kemtai/logger'

export enum Error {
    MediaDevicesNotSupported = "MEDIADEVICES_NOT_SUPPORTED",
    WebGLDisabled            = "WEBGL_DISABLED",
    InternalError            = "INTERNAL_ERROR",
    InsufficientHardware     = "INSUFFICIENT_HARDWARE",
    //BrowserNotSupported  = "BROWSER_NOT_SUPPORTED",
    ModelLoadingFailed       = "MODEL_LOADING_FAILED",
    ServerUnavailble         = "SEREVER_UNAVAILIBLE",
    NodeJSNotSupported       = "NODEJS_NOT_SUPPORTED",
}


const errorMessages : Map<string,string> = new Map([
    [Error.MediaDevicesNotSupported, "mediaDevices API is not supported or disabled by browser"],
    [Error.WebGLDisabled,            "WebGL is not supported or disabled by browser"],
    [Error.InternalError,            "tfjs unable initalise WebGL background"],
    [Error.InsufficientHardware,     "Detected hardware is insufficient to tun Kemtai"],
    [Error.ModelLoadingFailed,       "Error while loading models (probably due to network problems)"],
    [Error.ServerUnavailble,         "Server Communication Failed."],
    [Error.NodeJSNotSupported,       "NodeJS is not supported."],
])


export type KemtAPIStatus = {
    success : boolean
    error?  : string
    errorMessage? : string
}

export function err(error:string, errorMessage?:string):KemtAPIStatus{
    errorMessage = errorMessage || errorMessages.get(error)
    logger.event("kemtapi-error",{level:"kemtapi",error,errorMessage})

    return {
        success : false,
        error   : error,
        errorMessage : errorMessage 
    }
}

export function ok():KemtAPIStatus{
    return {
        success : true
    }
}

export interface KemtAPIInitParams {
    logServerUrl?    : string
    modelsUrlPrefix? : string
    modelsVersion?   : string
}

  
