/**
 * @license
 * Copyright 2018 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import {ENGINE} from '../engine';
import {RecoverCoords,  RecoverCoordsInputs} from '../kernel_names';
import {Tensor2D,Tensor4D} from '../tensor';
import {NamedTensorMap} from '../tensor_types';
import {convertToTensor} from '../tensor_util_env';

import {op} from './operation';


function recoverCoords_(heatmap: Tensor4D, offset: Tensor4D): Tensor2D {
  const $heatmap = convertToTensor(heatmap, 'heatmap', 'recoverCoords');
  const $offset  = convertToTensor(offset,  'offset', 'recoverCoords');

  const inputs: RecoverCoordsInputs = {heatmap : $heatmap, offset : $offset};

  return ENGINE.runKernel(RecoverCoords, 
                          inputs as {} as NamedTensorMap);
}
export const recoverCoords = op({recoverCoords_});
 