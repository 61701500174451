/**
 * @license
 * Copyright 2020 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import {KernelConfig, registerKernel} from '../tfjs-core';

import {_fusedMatMulConfig} from './kernels/_FusedMatMul';
//import {absConfig} from './kernels/Abs';
//import {acosConfig} from './kernels/Acos';
//import {acoshConfig} from './kernels/Acosh';
import {addConfig} from './kernels/Add';
//import {addNConfig} from './kernels/AddN';
import {allConfig} from './kernels/All';
import {anyConfig} from './kernels/Any';
import {argMaxConfig} from './kernels/ArgMax'; 
// import {avgPoolConfig} from './kernels/AvgPool';
// import {avgPool3DConfig} from './kernels/AvgPool3D';
// import {batchMatMulConfig} from './kernels/BatchMatMul';
// import {batchNormConfig} from './kernels/BatchNorm';
//import {batchToSpaceNDConfig} from './kernels/BatchToSpaceND';
//import {broadcastArgsConfig} from './kernels/BroadcastArgs';
import {castConfig} from './kernels/Cast';
//import {ceilConfig} from './kernels/Ceil';
//import {clipByValueConfig} from './kernels/ClipByValue';
import {concatConfig} from './kernels/Concat';
// import {conv2DConfig} from './kernels/Conv2D';
// import {conv3DConfig} from './kernels/Conv3D';
// import {cosConfig} from './kernels/Cos';
//import {coshConfig} from './kernels/Cosh';
// import {cropAndResizeConfig} from './kernels/CropAndResize';
//import {cumsumConfig} from './kernels/Cumsum';
//import {depthToSpaceConfig} from './kernels/DepthToSpace';
import {depthwiseConv2dNativeConfig} from './kernels/DepthwiseConv2dNative';
// import {diagConfig} from './kernels/Diag';
// import {dilation2DConfig} from './kernels/Dilation2D';
//import {einsumConfig} from './kernels/Einsum'; 
// import {eluConfig} from './kernels/Elu';
// import {equalConfig} from './kernels/Equal';
// import {expandDimsConfig} from './kernels/ExpandDims';
// import {fillConfig} from './kernels/Fill';
// import {flipLeftRightConfig} from './kernels/FlipLeftRight';
// import {floorConfig} from './kernels/Floor';
// import {floorDivConfig} from './kernels/FloorDiv';
import {fromPixelsConfig} from './kernels/FromPixels';
import {fusedConv2DConfig} from './kernels/FusedConv2D';
// import {fusedDepthwiseConv2DConfig} from './kernels/FusedDepthwiseConv2D';
//import {gatherNdConfig} from './kernels/GatherNd';
// import {gatherV2Config} from './kernels/GatherV2';
// import {greaterConfig} from './kernels/Greater';
// import {greaterEqualConfig} from './kernels/GreaterEqual';
import {identityConfig} from './kernels/Identity';
//import {isFiniteConfig} from './kernels/IsFinite';
//import {isInfConfig} from './kernels/IsInf';
//import {isNaNConfig} from './kernels/IsNaN';
// import {leakyReluConfig} from './kernels/LeakyRelu';
// import {lessConfig} from './kernels/Less';
// import {lessEqualConfig} from './kernels/LessEqual';
// import {logConfig} from './kernels/Log';
//import {logicalAndConfig} from './kernels/LogicalAnd';
//import {logicalNotConfig} from './kernels/LogicalNot';
//import {logicalOrConfig} from './kernels/LogicalOr';
//import {LRNConfig} from './kernels/LRN';
// import {maxConfig} from './kernels/Max';
// import {maximumConfig} from './kernels/Maximum';
// import {maxPoolConfig} from './kernels/MaxPool';
// import {maxPool3DConfig} from './kernels/MaxPool3D';
// import {maxPoolWithArgmaxConfig} from './kernels/MaxPoolWithArgmax';
// import {meanConfig} from './kernels/Mean';
// import {minConfig} from './kernels/Min';
// import {minimumConfig} from './kernels/Minimum';
// import {mirrorPadConfig} from './kernels/MirrorPad';
// import {modConfig} from './kernels/Mod';
// import {multinomialConfig} from './kernels/Multinomial';
import {multiplyConfig} from './kernels/Multiply';
// import {negConfig} from './kernels/Neg';
// import {notEqualConfig} from './kernels/NotEqual';
// import {oneHotConfig} from './kernels/OneHot';
// import {onesLikeConfig} from './kernels/OnesLike';
// import {packConfig} from './kernels/Pack';
import {padV2Config} from './kernels/PadV2';
// import {powConfig} from './kernels/Pow';
// import {preluConfig} from './kernels/Prelu';
// import {prodConfig} from './kernels/Prod';
// import {reciprocalConfig} from './kernels/Reciprocal';
// import {reluConfig} from './kernels/Relu';
// import {relu6Config} from './kernels/Relu6';
import {reshapeConfig} from './kernels/Reshape';
// import {resizeBilinearConfig} from './kernels/ResizeBilinear';
import {resizeNearestNeighborConfig} from './kernels/ResizeNearestNeighbor';
// import {reverseConfig} from './kernels/Reverse';
// import {rotateWithOffsetConfig} from './kernels/RotateWithOffset';
// import {roundConfig} from './kernels/Round';
// import {rsqrtConfig} from './kernels/Rsqrt';
//import {scatterNdConfig} from './kernels/ScatterNd';
// import {selectConfig} from './kernels/Select';
// import {seluConfig} from './kernels/Selu';
import {sigmoidConfig} from './kernels/Sigmoid';
// import {signConfig} from './kernels/Sign';
import {sliceConfig} from './kernels/Slice';
// import {softmaxConfig} from './kernels/Softmax';
// import {softplusConfig} from './kernels/Softplus';
// import {spaceToBatchNDConfig} from './kernels/SpaceToBatchND';
// import {splitVConfig} from './kernels/SplitV';
// import {sqrtConfig} from './kernels/Sqrt';
// import {squareConfig} from './kernels/Square';
//import {squaredDifferenceConfig} from './kernels/SquaredDifference';
//import {stepConfig} from './kernels/Step';
// import {stridedSliceConfig} from './kernels/StridedSlice';
import {subConfig} from './kernels/Sub';
import {sumConfig} from './kernels/Sum';
// import {tanConfig} from './kernels/Tan';
// import {tanhConfig} from './kernels/Tanh';
// import {tileConfig} from './kernels/Tile';
// import {topKConfig} from './kernels/TopK';
// import {transformConfig} from './kernels/Transform';
// import {transposeConfig} from './kernels/Transpose';
// import {unpackConfig} from './kernels/Unpack';
// import {zerosLikeConfig} from './kernels/ZerosLike';
import {recoverCoordsConfig} from './kernels/RecoverCoords';

// List all kernel configs here
const kernelConfigs: KernelConfig[] = [
//   LRNConfig,
//   _fusedMatMulConfig,
//   absConfig,
//   acosConfig,
//   acoshConfig,
   addConfig,
//   addNConfig,
   allConfig,
   anyConfig,
   argMaxConfig,
//   avgPool3DConfig,
//   avgPoolConfig,
//   batchMatMulConfig,
//   batchNormConfig,
//   batchToSpaceNDConfig,
//   broadcastArgsConfig,
   castConfig,
//   ceilConfig,
//   clipByValueConfig,
   concatConfig,
//   conv2DConfig,
//   conv3DConfig,
//   cosConfig,
//   coshConfig,
//   cropAndResizeConfig,
//   cumsumConfig,
//   depthToSpaceConfig,
   depthwiseConv2dNativeConfig,
//   diagConfig,
//   dilation2DConfig,
//   einsumConfig,
//   eluConfig,
//   equalConfig,
//   expandDimsConfig,
//   fillConfig,
//   flipLeftRightConfig,
//   floorConfig,
//   floorDivConfig,
   fromPixelsConfig,
   fusedConv2DConfig,
//   fusedDepthwiseConv2DConfig,
//   gatherNdConfig,
//   gatherV2Config,
//   greaterConfig,
//   greaterEqualConfig,
   identityConfig,
//   isFiniteConfig,
//   isInfConfig,
//   isNaNConfig,
//   leakyReluConfig,
//   lessConfig,
//   lessEqualConfig,
//   logConfig,
//   logicalAndConfig,
//   logicalNotConfig,
//   logicalOrConfig,
//   maxConfig,
//   maxPool3DConfig,
//   maxPoolConfig,
//   maxPoolWithArgmaxConfig,
//   maximumConfig,
//   meanConfig,
//   minConfig,
//   minimumConfig,
//   mirrorPadConfig,
//   modConfig,
//   multinomialConfig,
   multiplyConfig,
//   negConfig,
//   notEqualConfig,
//   oneHotConfig,
//   onesLikeConfig,
//   packConfig,
   padV2Config,
//   powConfig,
//   preluConfig,
//   prodConfig,
//   reciprocalConfig,
//   relu6Config,
//   reluConfig,
   reshapeConfig,
//   resizeBilinearConfig,
   resizeNearestNeighborConfig,
//   reverseConfig,
//   rotateWithOffsetConfig,
//   roundConfig,
//   rsqrtConfig,
//   scatterNdConfig,
//   selectConfig,
//   seluConfig,
   sigmoidConfig,
//   signConfig,
   sliceConfig,
//   softmaxConfig,
//   softplusConfig,
//   spaceToBatchNDConfig,
//   splitVConfig,
//   sqrtConfig,
//   squareConfig,
//   squaredDifferenceConfig,
//   stepConfig,
//   stridedSliceConfig,
   subConfig,
   sumConfig,
//   tanConfig,
//   tanhConfig,
//   tileConfig,
//   topKConfig,
//   transformConfig,
//   transposeConfig,
//   unpackConfig,
//  zerosLikeConfig

    recoverCoordsConfig,

];

for (const kernelConfig of kernelConfigs) {
  registerKernel(kernelConfig);
}
