import React, {useState, useEffect} from 'react';
import './App.css';
import {GPU} from '@kemtai/utils'
import kemtapi from '@kemtai/skeletapi'
import { onFrameSkeleton } from './draw-skeleton';

const MV = [
'2024-08-21:210135:de8f3c88e8ffa3:8755-3-way_Leg_Exercise:d7b91eb5a859f5:1724263295125.mp4',
'2024-08-21:210315:de8f3c88e8ffa3:4874-Active_Halfway_Straight_Leg_Raise:d7b91eb5a859f5:1724263395330.mp4',
'2024-08-25:074447:de8f3c88e8ffa3:8755-3-way_Leg_Exercise:5f54618f09dac0:1724561087202.mp4',
'2024-08-25:074537:de8f3c88e8ffa3:4874-Active_Halfway_Straight_Leg_Raise:5f54618f09dac0:1724561137340.mp4',
]

async function initApp() {

    const CameraConstraints = {
        video: {
            width: 640,
            height: 480,
            facingMode: 'user'
          }
    }
    
    //const stream = await navigator.mediaDevices.getUserMedia(CameraConstraints);
    await kemtapi.init({})
    //kemtapi.setInputVideoStream(stream)
    const status2 = await kemtapi.loadModels()
    console.log(status2)
    kemtapi.registerFrameSkeletonCallback(onFrameSkeleton, "onFrameSkeleton")


}

function videoUrl(videoIndex: number) {
    return `/MOR/${MV[videoIndex]}`
}

function VideoPreview({videoIndex, onClick}: {videoIndex: number, onClick:(idx:number) => void}) {
    return (
        <video src={videoUrl(videoIndex)} playsInline autoPlay muted loop height={100} className='preview-video'
         onClick={() => onClick(videoIndex)}
        />
    )
}




function App() {
  const [videoIndex, setVideoIndex] = useState<number>(0)
  const gpu = GPU()

  useEffect(() => {
    kemtapi.camera.setDebugVideo(`/MOR/${MV[videoIndex]}`)
  }, [videoIndex])


  const onClick = (idx: number) => {
    setVideoIndex(idx)
  }

  return (
    <div className="App">
        {/* <p>{gpu}</p> */}
        <div className='preview-container'>
        <VideoPreview videoIndex={0} onClick={onClick} />
        <VideoPreview videoIndex={1} onClick={onClick} />
        <VideoPreview videoIndex={2} onClick={onClick} />
        <VideoPreview videoIndex={3} onClick={onClick} />
        </div>
        <div className='skel-container'>
        <canvas id="canvas"  className="skel-canvas" 
              width={kemtapi.camera.videoWidth || 720} height={kemtapi.camera.videoHeight || 1200} ></canvas>
        </div>
    </div>
  );
}


initApp()

export default App;
