
import {  tf } from './my-tf'

if (typeof global !== 'undefined') {
    (global as any).$RefreshReg$ = () => {};
    (global as any).$RefreshSig$ = () => () => {};
}

function print(message:string) {
    console.log(`%c${message}`,"color:magenta")
}

function TfBackend(){
    const t = tf.tensor([1, 2, 3, 4]);
    const backend = tf.getBackend();
    t.dispose()
    return backend;
}

export function tfMemory() {
     return tf.memory ()
}


export function hasWebGL() {
    const canvas = document.createElement("canvas");
    const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    const result = gl && gl instanceof WebGLRenderingContext
    canvas.remove()  
    //console.log("remove webcontext (hasWebGL)")
    return result;
}

export function isWebGLEnabled(){
    try {
        return TfBackend() === 'webgl';
    } catch {
        return false
    }
}


export function convertURIToImageData(URI:string) : Promise<ImageData> {
    //console.log("============> ",URI)
    return new Promise(function(resolve, reject) {
      if (URI == null) return reject();
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');
      let image = new Image();
      image.crossOrigin = "anonymous";

      image.addEventListener('load', function() {
        canvas.width = image.width;
        canvas.height = image.height;
        context!.drawImage(image, 0, 0, canvas.width, canvas.height);
        resolve(context!.getImageData(0, 0, canvas.width, canvas.height));
      }, false);
      image.src = URI;
    });
}


export interface SystemStatus {
    ok?  : boolean,
    FPS? : number,
    time? : number
    backend? : string,
    err? : string,
    fpsWasOk?:boolean,
    tfjsVersion?:string,
    tfTest:number
}


export class Cntr {
    count:number = 0
    sum:number = 0
    sum2:number = 0

    tick(v:number){
        this.count++
        this.sum  += v
        this.sum2 += v*v
    }

    reset(){
        this.count = 0
        this.sum = 0
        this.sum2 = 0
    }

    get avg() {
        return this.sum / this.count
    }

    get stddev() {
        const avg = this.avg
        return Math.sqrt(this.sum2/this.count - avg * avg )
    }
    print(msg:string=""){
        console.log(`%c ${msg} N=${this.count} avg=${this.avg} ms stdev=${this.stddev}`,"color:blue")
    }
};
export class Tmr1 extends Cntr {
    started : number = Date.now()
    chunk = 0

    constructor(public chunkSize:number = 100,
                public name = "",
                public style = "background-color:aquamarine" ){
        super()
    }

    start(){
        this.started = Date.now()
    }
    stop(){
        this.tick(Date.now()-this.started)
        if (this.count >= this.chunkSize) {
            this.report()
            this.reset()
            this.chunk++
        }
    }
    report(msg:string = ""){
        const text = `${msg} ${this.name} ${this.chunk} | ${Math.round(this.avg)}ms. stddev=${Math.round(this.stddev)} n=${this.count}`;
        console.log(`%c ${text}`, this.style)
    }

}




export class FPSSmoother2 {
    current : Cntr
    last : Cntr|null = null
    lastFrameTime : number = Date.now()
    rawtime: number = 0;
    count = 0
    chunkSize = 100
    chunk=0

    constructor (chunkSize:number = 100) {
        this.chunkSize = chunkSize
        this.current = new Cntr()
    }

    reset() {
        this.current = new Cntr()
        this.last = null
        this.count = 0
    }

    onPredict() {
      this.rawtime = Date.now() - this.lastFrameTime;
      this.current.tick(this.rawtime)
      if (this.current.count>=this.chunkSize){
        this.last = this.current
        this.current = new Cntr()
        //this.print()
        this.chunk++;
        this.current.tick(this.rawtime)
      }
      this.count ++
      this.lastFrameTime = Date.now()
      return this.FPS
    }

    get cntr() : Cntr {
        return this.last || this.current
    }
    get time() {
        return this.cntr.avg
    }
    get stddev() {
        return this.cntr.stddev
    }
    get FPS(){
       return 1000/this.time;
    }
    print(){
        const text = `${this.chunk} | ${Math.round(this.time)}ms. stddev=${Math.round(this.stddev)} n=${this.cntr.count} FPS:${Math.round(this.FPS)}`;
        console.log(`%c ${text}`, "background-color:aquamarine")
    }
}



export class FPSSmoother {
    readonly alpha : number;
    lastFrameTime : number = Date.now()
    public time : number   = 20;
    public rawtime: number = 0;
    count = 1

    constructor (alpha = 0.95) {
        this.alpha = alpha
    }

    reset() {
        this.lastFrameTime = Date.now()
        this.time = 40
        this.count = 0
    }


    get xalpha() {
        if ( this.count < 2 ) {
            return 0
        }
        if ( this.count < 10 ) {
            return (1-this.count/10) * this.alpha
        }
        return this.alpha
    }

    onPredict() {
      this.rawtime = Date.now() - this.lastFrameTime;
      this.count ++
      if (this.count>4) {
          const alpha = this.xalpha
          this.time = alpha * this.time + (1-alpha) * this.rawtime
      }
      //console.log("=========================",this.rawtime, this.time)
      this.lastFrameTime = Date.now()
      return this.FPS
    }

    get FPS(){
       return 1000/this.time;
    }
}

/*
export class Smoother {
    readonly alpha = 0.99;
    readonly N = 200;
    val : number;
    rawval : number = 0;
    private last : number;
    count : number = 0;
    private totN : number = 0;
    private expval : number = 0;

    constructor (val = 0, public name = "smoother") {
        this.val   = val
        this.last  = val
        this.rawval = val
        this.expval = val
    }

    add(val:number) {
      this.count ++
      this.rawval = val
      this.last = val


      if (this.count < this.N) {
          this.totN += val
          this.val = this.totN / this.count
      } else if (this.count % this.N == 0) {
          this.val = this.totN / this.N
          this.totN = val
      } else {
          this.totN += val
      }
      //console.log("Smoother:",this.name,this.count,"   :   ", Math.round(this.val), Math.round(this.expval), "           ", Math.round((this.expval-this.val)/this.val*100))
      return this.FPS
    }

    get FPS(){
       return 1000/this.val
    }
}
*/

export function tfTest() {
    return tf.tidy(()=> tf.add(tf.scalar(1) , tf.scalar(2)).dataSync()[0])
}



export class Smoother {
    readonly alpha : number;
    val : number;
    rawval : number = 0;
    last : number;
    count : number = 0;

    constructor (val = 100, alpha = 0.9) {
        this.alpha = alpha
        this.val   = val
        this.last  = val
        this.rawval = val
    }
    add(val:number) {
      if (this.count) {
          this.rawval = val
          const v = Math.min(val,this.last)
          this.val = this.alpha * this.val + (1-this.alpha) * v
          this.last = val
      } else {
        this.rawval = val
        this.val    = val
        this.last   = val
      }
      this.count ++

      return this.FPS
    }
    set(val:number) {
        this.val = val
        return this.FPS
    }
    get FPS(){
       return 1000/this.val
    }
}


/*
interface GPUInfo {
    //time    : string,
    //utime   : number,
    browser : string,
    oscpu?   : string,
    app_name : string,
    app_version : string,
    gpu_vendor  : string,
    gpu_renderer : string,
    error? : any,
    gl_version : string,
    gl_sl_version : string
}

export function gpuInfo():GPUInfo {
    let canvas3d = document.createElement("canvas")
    let webglCtx : any = null;
    let result : GPUInfo = {
        //time    : Date(),
        //utime   : Date.now(),
        browser : navigator.userAgent,
        //oscpu   : navigator.oscpu,
        app_name    : navigator.appName,
        app_version : navigator.appVersion,
        gpu_vendor  : "",
        gpu_renderer : "",
        gl_version   : "",
        gl_sl_version : ""
    }

    try {
        webglCtx = canvas3d.getContext("webgl") || canvas3d.getContext("experimental-webgl")
        if (webglCtx) {

            result.gl_version    = (webglCtx as any).getParameter(webglCtx.VERSION)
            result.gl_sl_version = (webglCtx as any).getParameter(webglCtx.SHADING_LANGUAGE_VERSION)
            const debugInfo = webglCtx.getExtension("WEBGL_debug_renderer_info")
            if (debugInfo) {
                result.gpu_vendor    = (webglCtx as any).getParameter(debugInfo?.UNMASKED_VENDOR_WEBGL)
                result.gpu_renderer  = (webglCtx as any).getParameter(debugInfo?.UNMASKED_RENDERER_WEBGL)
            } else {
                result.error = "WEBGL_debug_renderer_info not supported"
            }
            webglCtx.getExtension('WEBGL_lose_context').loseContext();
        } else {
            result.error = "cannot get webgl context"
        }

    } catch (e) {
        console.log("Exception:",e)
        result.error = e
    } finally {
        canvas3d.remove()
        //console.log("remove webcontext")
    }
    return result
}
*/
/*
function simpifyGPU(name:string)  {
    name = name.replace(/ ?\(TM\) ?/g, " ")
    name = name.replace(/ ?\(R\) ?/g, " ")
    return name
}

export function GPU() : string|null {
    let canvas3d : OffscreenCanvas|HTMLCanvasElement
    if (typeof OffscreenCanvas !== "undefined") {
        canvas3d = new OffscreenCanvas(10,10)
    } else {
        canvas3d = document.createElement('canvas');
    }
    try {
        let webglCtx = canvas3d.getContext("webgl")
        if (webglCtx) {
            const debugInfo = (webglCtx as any).getExtension("WEBGL_debug_renderer_info")
            if  (debugInfo) {
                const gpu : string = (webglCtx as any).getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
                const result = simpifyGPU(gpu);
                (webglCtx as any).getExtension('WEBGL_lose_context').loseContext();
                return result;
            }
        }
    } catch (e) {
    } finally {
        if (canvas3d instanceof HTMLCanvasElement) {
             canvas3d.remove()
        }
    }
    return null
}
*/

export function a7_192() {
    const model = tf.sequential();

    model.add(tf.layers.inputLayer({inputShape: [192,192,3]}));
    model.add(tf.layers.conv2d({kernelSize:3, filters:32, padding: 'same', activation:'relu', strides:2}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:64, padding: 'same', activation:'relu'}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:64, padding: 'same', activation:'relu'}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:128, padding: 'same', activation:'relu', strides:2}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:128, padding: 'same', activation:'relu'}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:256, padding: 'same', activation:'relu', strides:2}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:256, padding: 'same', activation:'relu'}))
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:256, padding: 'same', activation:'relu', strides:2}))
    for(let i=0; i<= 10; i ++){
        model.add(tf.layers.separableConv2d({kernelSize:3, filters:256, padding: 'same', activation:'relu'}))
    }
    model.add(tf.layers.separableConv2d({kernelSize:3, filters:512, padding: 'same', activation:'relu'}))

    model.add(tf.layers.conv2d({kernelSize:3, filters:3*42, padding: 'same', activation:'relu'}))
    return model
}


//checkPredictionSpeed()
/*
export async function gpuSelectModel(gpu:string) : Promise<string[]>
{
    for (let [kw,gid] of GPU2GROUP) {
        if (gpu.includes(kw)){
            console.log(`gpuSelectModel selected group ${gid} for ${gpu} (kw ${kw})`)
            return GROUP2MODEL[gid]
        }
    }

    const time = await checkPredictionSpeed()
    const fps = 1000/time
    let group = "unknown"
    console.log(`gpuSelectModel fps=${fps}`)

    for (let [minFps,gid] of TIMING2GROUP) {
        if (fps>minFps) {
            group = gid
        } else {
            break
        }
    }
    console.log(`gpuSelectModel group=${group}`)

    return GROUP2MODEL[group]
}
*/

/////////////////////////////////////////////////////////////////
/*

function createCanvas() {
    if (typeof OffscreenCanvas !== 'undefined') {
      print("createCanvas has OffscreenCanvas")
      return new OffscreenCanvas(300, 150);
    } else if (typeof document !== 'undefined') {
      return document.createElement('canvas');
    } else {
      return null
    }
}

const WEBGL_ATTRIBUTES: WebGLContextAttributes = {
    alpha: false,
    antialias: false,
    premultipliedAlpha: false,
    preserveDrawingBuffer: false,
    depth: false,
    stencil: false,
    failIfMajorPerformanceCaveat: true
  };

function getWebGLRenderingContext(canvas : OffscreenCanvas | HTMLCanvasElement) {
    for (let cntxt of ['webgl2','webgl','experimental-webgl']) {
        const ctx = canvas.getContext(cntxt)
        if (ctx) {
            print(`getWebGLRenderingContext: Got ${cntxt}`)
            break
        }
    }
    for (let cntxt of ['webgl2','webgl','experimental-webgl']) {
        const ctx2 = canvas.getContext(cntxt,WEBGL_ATTRIBUTES)
        if (ctx2) {
            print(`getWebGLRenderingContext: Got ${cntxt} with attributes`)
            break
        }
    }

    return null
}


function testWebGL(){
    console.log("tf.version:",tf.version)
    const c1 = createCanvas()
    console.log(`%ctestWebGL createCanvas: ${c1 !== null ? "OK" : "Nop"}`,"color:magenta")
    if (c1) {
        getWebGLRenderingContext(c1)
    }
}

testWebGL()

*/

//print(`tfjs version: ${tf.version.tfjs}`)


export {GPU} from '@kemtai/utils'